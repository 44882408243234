<style lang="scss" scoped>
// ********ALL PRODUCTS SECTION*********
.all-products {
  background: #007fcf24;
  height: 120px;
  .title {
    font-weight: 600;
    color: #00236f;
  }
}

.greenBackground {
  background-color: #8fcf00 !important;
}

.show-more a {
  font-weight: 700;
  color: #000;
}
</style>
<template>
  <div>
    <div class="loading" v-if="$store.getters.loading">
      <div class="spinner-grow text-success"></div>
    </div>

    <div v-else>
      <div
        class="
          row
          m-0
          all-products
          text-center
          d-flex
          text-white
          align-content-center
        "
      >
        <div
          class="col-md-12"
          v-if="
            typeof $store.getters.categories !== 'undefined' &&
            typeof $store.getters.bizneset !== 'undefined'
          "
        >
          <h3
            class="title"
            v-if="
              $store.getters.categories.find((x) => x.slug === activeCategory)
            "
          >
            {{
              $store.getters.categories.find((x) => x.slug === activeCategory)
                .name
            }}
          </h3>
          <h3
            class="title"
            v-else-if="
              $store.getters.bizneset.find((x) => x.slug === activeCategory)
            "
          >
            {{
              $store.getters.bizneset.find((x) => x.slug === activeCategory)
                .name
            }}
          </h3>
        </div>
      </div>

      <Categories @callProducts="callProducts" :selectedCategoryGeneratedTag="selectedCategoryGeneratedTag" :subCategories="subCategories" />

      <div class="container pt-5 pb-4 pl-sm-0 pr-sm-0">
        <div class="category-products">
          <div class="row">
            <Product
              :root="'shop'"
              colLg="3"
              colMd="4"
              :data="product"
              :show="
                currentUrl === 'shop'
                  ? !product.Categories.find((c) => c.name == 'Web')
                  : !product.Categories.find((c) => c.name == 'E-Shop')
              "
              v-for="product in products"
              :key="product.id"
            />
          </div>
        </div>
        <div
          v-if="this.maxNumberOfPages > this.page"
          class="text-center show-more mt-5"
        >
          <button
            class="btn greenBackground"
            v-if="!loadingMore"
            @click="loadMoreProducts()"
          >
            Shfaq më shumë
          </button>
          <button v-else class="btn greenBackground" type="button" disabled>
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span class="sr-only">Loading...</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Product from "../../layout/Product.vue";
import Categories from "../../layout/Categories";
import { url } from "../../../url.js";
import defaultImage from "@/assets/images/paisje.svg";

export default {
  data() {
    return {
      categories: [],
      activeCategory: this.$route.params.category,
      page: this.$route.params.page,
      loadingMore: false,
      perPage: 20,
      products: [],
      maxNumberOfPages: 0,
      currentUrl: this.$route.name.toLowerCase().includes("shop")
        ? "shop"
        : "web",
      callProdcutsPerCategory: false,
      paramToCall: "",
      subCategories: [],
      selectedCategoryGeneratedTag: ''
    };
  },
  beforeCreate() {},
  beforeMount() {
    window.scrollTo(0, 0);
    this.getProducts(this.activeCategory, this.page);
  },
  // beforeRouteUpdate (to, from, next) {
  //     // react to route changes...
  //     // don't forget to call next()
  //     this.activeCategory = to.params.category
  //     this.page = to.params.page
  //     this.getProducts(to.params.category, to.params.page)
  //     next()
  // },
  components: {
    Product,
    Categories,
  },
  watch: {
    callProdcutsPerCategory: function (val) {
      if (val) {
        this.getProducts(this.paramToCall, this.page);
        this.callProdcutsPerCategory = false;
      }
    },
  },
  methods: {
    callProducts(e) {
      this.subCategories = e.subCategories;
      this.paramToCall = e.param;
      this.callProdcutsPerCategory = e.call;
    },
    getProducts(category, page) {
      this.$store.commit("changeLoading", true);

      this.$axios
        .get(
          url +
            "category?slug=" +
            category +
            "&page=" +
            page +
            "&perPage=" +
            this.perPage
        )
        .then((res) => {
          this.products = res.data.products;
          this.selectedCategoryGeneratedTag = `${res.data.selectedCategory.slug}#${res.data.selectedCategory.term_id}`
          this.maxNumberOfPages = res.data.maxNumberOfPages;
          this.$store.commit("changeLoading", false);
        })
        .catch((error) => {
          this.$store.commit("changeLoading", false);
        });
    },
    loadMoreProducts() {
      this.page++;
      this.loadingMore = true;
      this.$axios
        .get(
          url +
            "category?slug=" +
            this.activeCategory +
            "&page=" +
            this.page +
            "&perPage=" +
            this.perPage
        )
        .then((res) => {
          var currentProducts = this.products;
          var newProducts = res.data.products;
          this.products = currentProducts.concat(newProducts);
          this.maxNumberOfPages = res.data.maxNumberOfPages;
          this.loadingMore = false;
        })
        .catch((error) => {
          this.$store.commit("changeLoading", false);
          this.loadingMore = false;
        });
    },
    imgError(e) {
      e.target.src = defaultImage;
      // e.target.classList.toggle('img-error')
    },
  },
};
</script>
