<style lang="scss" scoped>
.product {
  border: 1px solid #eeeeee;
  padding: 10px 20px;
  text-align: center;
  #productPrice {
    font-weight: 600;
    color: #365a6e;
  }
  .product-title {
    color: #365a6e;
    font-weight: 600;
  }
  .product-img {
    max-width: 100px;
    width: 100%;
    height: 100px;
    object-fit: contain;
  }
  .center {
    display: flex;
    align-items: center;
  }
  .remove-product {
    color: #ff2742cb;
    cursor: pointer;
  }
  .quantity {
    position: relative;
    input {
      border: none;
      border-radius: 100px;
      padding: 5px;
      color: #365a6e;
      font-weight: 700;
      text-align: center;
    }
    .input-shop {
      background: #8fcf0033;
    }
    .input-web {
      background: #007fcf34;
    }
    .minus-btn {
      cursor: pointer;
      text-decoration: none;
      position: absolute;
      left: 5px;
      color: #95bace;
      font-size: 20px;
    }
    .plus-btn {
      cursor: pointer;
      text-decoration: none;
      position: absolute;
      right: 5px;
      color: #95bace;
      font-size: 20px;
    }
  }
}

@media (max-width: 575px) {
  .product {
    .center {
      display: inline !important;
    }
    .remove-row {
      width: 15%;
      align-self: center;
    }
    .quantity-row {
      width: 85%;
    }
  }
}
</style>
<template>
  <div class="row product">
    <router-link
      :to="{ name: 'ProductDetails' + currentUrl, params: { slug: data.ID } }"
      class="col-sm-2"
      ><img
        class="product-img"
        v-if="typeof data.Fotoja !== 'undefined'"
        :src="data.Fotoja[0]"
    /></router-link>
    <div class="col-sm-6 center d-flex justify-content-between">
      <p class="product-title mb-0">{{ data.Product }}</p>
      <p class="product-title mb-0" v-if="data.quantity">
        {{ (data.quantity * data.RetailPrice).toFixed(2) }} €
      </p>
      <p class="product-title mb-0" v-else>{{ data.RetailPrice }} €</p>
    </div>
    <div class="col-sm-3 quantity-row center">
      <div class="quantity">
        <a
          @click="quantity > 1 ? changeQuantity('minus') : ''"
          class="minus-btn"
          >-</a
        >
        <input disabled class="form-input w-100 input-web" v-model="quantity" />
        <a @click="changeQuantity('plus')" class="plus-btn">+</a>
      </div>
    </div>
    <div class="col-sm-1 remove-row center">
      <a @click="removeFromCart(data.ID)" class="remove-product"
        ><i class="fas fa-times"></i
      ></a>
    </div>
  </div>
</template>

<script>
import { url, authHeader } from "../../url";
import store from "../../store/index";

export default {
  data() {
    return {
      quantity: 1,
      isInCart: null,
      currentUrl: this.$route.name.toLowerCase().includes("shop")
        ? "shop"
        : "web",
    };
  },
  mounted() {
    if (typeof this.$store.getters.cart.card !== "undefined") {
      var cartData = this.$store.getters.cart.card;

      for (var i = 0; i < cartData.length; i++) {
        if (cartData[i].ID === this.data.ID) {
          this.isInCart = i;
          if (!cartData[i].Categories.includes("Oferta Javore")) {
            this.quantity = cartData[i].quantity;
          } else {
            cartData[i].quantity = 1;
          }
        }
      }
    }
  },
  props: ["data"],
  methods: {
    changeQuantity(action) {
      if (action == "minus") this.quantity = this.quantity - 1;
      else this.quantity = this.quantity + 1;

      if (this.$store.getters.logged && this.isInCart != null) {
        let data = {
          quantity: this.quantity,
          variation_id: 0,
          product_id: this.data.ID,
        };

        this.$axios
          .post(url + "addToCart", data, { headers: { ...authHeader() } })
          .then((res) => {
            this.isInCart = true;
            this.$store.commit("fillCart", res.data);
          })
          .catch((e) => {
            console.log(e);
          });
      } else if (this.isInCart != null) {
        var cartData = this.$store.getters.cart;

        if (cartData == false) {
          this.data.quantity = this.quantity;

          cartData = {
            shipping: 0,
            card: [this.data],
            total: this.data.RetailPrice,
          };
        } else if (this.isInCart != null) {
          var sumToBeAdded =
            (this.quantity - cartData.card[this.isInCart].quantity) *
            parseFloat(this.data.RetailPrice).toFixed(2);
          cartData.card[this.isInCart].quantity = this.quantity;
          sumToBeAdded = parseFloat(cartData.total) + parseFloat(sumToBeAdded);
          cartData.total = sumToBeAdded.toFixed(2);
        } else {
          cartData.card.push(this.data);
          var sumToBeAdded =
            parseFloat(cartData.total) +
            parseFloat(this.quantity * this.data.RetailPrice);
          cartData.total = sumToBeAdded.toFixed(2);
        }

        this.$store.commit("fillCart", cartData);
        localStorage.setItem("cart", JSON.stringify(cartData));
      }
    },
    removeFromCart(product_id) {
      this.$store.commit("changeLoading", true);

      let data = {
        product_id: product_id,
        variation_id: 0,
      };

      if (this.$store.getters.logged) {
        this.$axios
          .post(url + "removeFromCart", data, { headers: { ...authHeader() } })
          .then((res) => {
            this.$store.commit("fillCart", res.data);
            this.isInCart = null;
            this.$store.commit("changeLoading", false);
            this.quantity = 1;
          })
          .catch((error) => {
            this.$store.commit("changeLoading", false);
          });
      } else {
        this.isInCart = null;
        var cartData = this.$store.getters.cart;
        var removeKey = -1;

        for (const [key, value] of Object.entries(cartData.card)) {
          if (value.ID == this.data.ID) removeKey = key;
        }

        cartData.card.splice(removeKey, 1);
        var sumToBeAdded =
          parseFloat(cartData.total) -
          parseFloat(this.quantity * this.data.RetailPrice);
        cartData.total = sumToBeAdded.toFixed(2);
        this.$store.commit("fillCart", cartData);
        localStorage.setItem("cart", JSON.stringify(cartData));
        this.quantity = 1;
        this.$store.commit("changeLoading", false);
      }
    },
  },
};
</script>
