<style lang="scss" scoped>
    // ********ALL PRODUCTS SECTION********* 
    .all-products {
        .title {
            color: #007FCF;
        }
    }

    .show-more a {
        font-weight: 700;
        color: #000;
    }
</style>
<template>
    <div>
        <div class="loading" v-if="$store.getters.loading">
            <div class="spinner-grow text-success"></div>
        </div>
        
        <div class="pt-5 pb-5 all-products" v-else>
            <h5 class="title text-center mb-4">Rrezultatet e kerkimit "{{ activeInput }}"</h5>
            <div class="container pt-5 pb-4 pl-sm-0 pr-sm-0">
                <div class="category-products">
                    <div class="row">
                        <Product root="shop" colLg="3" colMd="4" :data="product" v-for="product in products" :key="product.id"/>
                    </div>
                </div>
                <!-- <div class="text-center show-more mt-5">
                    <a href="#">Shfaq më shumë</a>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
    import Product from "../layout/Product.vue"
    import { url } from '../../url.js'

    export default {
        data() {
            return {
                activeInput: this.$route.params.input,
                products: [],
            }
        },
        beforeCreate() {
            this.$axios.post(url+'headerSearch').then(res=>{
                this.products = res.data.products
                this.$store.commit('changeLoading', false)
            }).catch(error => {

            })
        },
        beforeMount() {
            this.getProducts(this.activeInput)
        },
        // beforeRouteUpdate (to, from, next) {
        //     this.activeInput = to.params.input
        //     this.getProducts(to.params.input, to.params.page)
        //     next()
        // },
        components: {
            Product
        },
        methods: {
            getProducts(input) {
                this.$store.commit('changeLoading', true)

                this.$axios.post(url+'headerSearch', { 'search': input}).then(res=>{
                    this.products = res.data.products
                    this.$store.commit('changeLoading', false)
                }).catch(error => {
                    
                })
            }
        }
    }
</script>
