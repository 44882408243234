var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$store.getters.loading)?_c('div',{staticClass:"loading"},[_c('div',{staticClass:"spinner-grow text-success"})]):_c('div',[_c('div',{staticClass:"carousel slide",attrs:{"id":"carouselExampleSlidesOnly","data-ride":"carousel"}},[_c('div',{staticClass:"carousel-inner"},_vm._l((_vm.banners),function(banner){return _c('div',{key:banner.id,staticClass:"carousel-item active banner"},[(typeof banner.Fotoja !== 'undefined')?_c('img',{staticClass:"d-block w-100",attrs:{"src":banner.Fotoja,"alt":"First slide"}}):_vm._e(),_vm._m(0,true)])}),0)]),_c('div',{staticClass:"categories pt-5 pb-5"},[_c('h2',{staticClass:"title"},[_vm._v("KATEGORITË")]),_c('div',{staticClass:"categoriesContainer"},[_c('div',{staticClass:"fullWidthCategories"},[_c('div',{staticClass:"container"},_vm._l((_vm.$store.getters.categories),function(category){return _c('router-link',{key:category.ID,staticClass:"carousel-content",attrs:{"to":{
                name: 'CategoryProductsWeb',
                params: { category: category.slug, page: 1 },
              }},on:{"click":function($event){return _vm.changeRoute(category, 'sub')}}},[_c('div',{staticClass:"item"},[_c('div',[_c('img',{staticClass:"icon",attrs:{"src":category.FotojaAktive}}),_c('h5',{staticClass:"text carousel-content-text"},[_vm._v(" "+_vm._s(category.name)+" ")])])])])}),1)])])]),_c('div',{staticClass:"our-mission pt-5 pb-5 bg-white"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('h1',{staticClass:"title mt-5"},[_vm._v("MISIONI YNË")]),_c('p',{staticClass:"first-description"},[_vm._v(" Synimi i Shkel e Shko Sh.P.K. është instalimi i kulturës së higjijenës me standarde Europiane duke ofruar një gamë të gjërë të produkteve kualitative. ")]),_vm._m(1),_c('router-link',{staticClass:"btn learn-more",attrs:{"to":{ name: 'AboutUsWeb' }}},[_vm._v("Për ne")])],1),_vm._m(2)])])]),_c('div',{staticClass:"services pt-5 pb-5"},[_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"container text-center"},[_c('h2',{staticClass:"title"},[_vm._v("BIZNESET")]),_c('div',{staticClass:"business-partners"},_vm._l((_vm.$store.getters.bizneset),function(business){return _c('div',{key:business.ID,staticClass:"item"},[_c('router-link',{staticClass:"carousel-content",attrs:{"to":{
                  name: 'CategoryProductsWeb',
                  params: { category: business.slug, page: 1 },
                }}},[_c('div',[_c('img',{staticClass:"icon",staticStyle:{"width":"50px","height":"50px"},attrs:{"src":require("../../../assets/images/logo2.svg")}}),_c('h5',{staticClass:"text carousel-content-text"},[_vm._v(" "+_vm._s(business.name)+" ")])])])],1)}),0)])])]),_vm._m(3),_c('div',{staticClass:"services pt-5 pb-5"},[_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"container text-center"},[_c('h2',{staticClass:"title"},[_vm._v("KLIENTËT")]),_c('div',{staticClass:"business-partners"},_vm._l((_vm.$store.getters.bizneset),function(business){return _c('div',{key:business.ID,staticClass:"item"},[_c('router-link',{staticClass:"carousel-content",attrs:{"to":{
                  name: 'CategoryProductsWeb',
                  params: { category: business.slug, page: 1 },
                }}},[_c('div',[_c('img',{staticClass:"icon",staticStyle:{"width":"50px","height":"50px"},attrs:{"src":require("../../../assets/images/logo2.svg")}}),_c('h5',{staticClass:"text carousel-content-text"},[_vm._v(" "+_vm._s(business.name)+" ")])])])],1)}),0)])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center text w-100"},[_c('h1',{staticClass:"title"},[_vm._v("Shkel e Shko")]),_c('h3',{staticClass:"description mt-3"},[_vm._v("Professional Cleaning Soulution")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-12 pl-3 ml-3 second-description"},[_c('p',[_vm._v(" Vitet në vijim do të karakterizohen me investime të mëdhaja në produkte me ISO dhe HACCP standarde dhe për këtë arsyje ne e shofim këtë si mundësi dhe fokus në investimet e ardhshme. - Të ofrojmë produkte për pastrim të kualitetit të lartë dhe produkte kimike me efikasitet sa më të lartë. - Të zhvillojmë një sistem të distribuimit sa më të shpejtë dhe efikas duke llogaritur në një numer te lartë të produkteve të cilat gjinden në stokun tonë. - Të lansojmë një vijë moderne dhe biodegraduese të produkteve kimike. ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-6"},[_c('img',{staticClass:"w-100 h-100",attrs:{"src":require("@/assets/images/office.png")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sales"},[_c('picture',{staticClass:"d-block w-100"},[_c('source',{staticClass:"w-100",attrs:{"srcset":require("@/assets/images/1920x325.png"),"media":"(min-width: 991px)"}}),_c('source',{staticClass:"w-100",attrs:{"srcset":require("@/assets/images/768x325.png"),"media":"(max-width: 991px)"}}),_c('source',{staticClass:"w-100",attrs:{"srcset":require("@/assets/images/450x325.png"),"media":"(max-width: 450px)"}}),_c('img',{staticClass:"w-100",attrs:{"src":require("@/assets/images/1920x325.png"),"alt":"Baneri"}})])])}]

export { render, staticRenderFns }