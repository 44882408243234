<style lang="scss" scoped>
.breadcrumb {
  background: none;
  font-size: 12px;
  .breadcrumb-item a {
    color: #95bace;
  }
  .active {
    color: #365a6e;
  }
}

.product .carousel-indicators {
  position: initial !important;
}
.product .carousel-indicators li {
  width: 60px;
  height: 60px;
}
.fa-star {
  font-size: 12px;
  color: #feb53e;
  opacity: 0.2;
}
.fa-star.checked {
  color: #feb53e;
  opacity: 1;
}
.product {
  .title {
    font-size: 24px;
  }
  .title,
  .reviews {
    color: #365a6e;
  }
  .reviews {
    font-size: 12px;
    line-height: 1;
  }
  .prices {
    .price {
      color: #95bace;
      font-size: 12px;
      margin-bottom: 0;
      display: flex;
      align-items: center;
    }
    .discount-price {
      color: #feb53e;
      font-size: 20px;
      margin-bottom: 0;
    }
  }
  .main-description {
    color: #627a86;
    font-size: 14px;
    ul {
      list-style: none;
      padding-left: 1rem;
      font-style: italic;
      color: #95bace;
      li:before {
        content: "\2022";
        color: #feb53e;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
      }
    }
  }
  .card-buttons {
    .add-to-cart {
      cursor: pointer;
      .round.active {
        background: #cf0000;
        color: #fff;
        .cart-img {
          background: url("../../../assets/images/cartWhite.svg");
        }
      }
    }
    .quantity {
      position: relative;
      input {
        border: none;
        border-radius: 100px;
        height: 42px;
        color: #365a6e;
        font-weight: 700;
        text-align: center;
      }
      .input-shop {
        background: #8fcf0033;
      }
      .input-web {
        background: #007fcf34;
      }
      .minus-btn {
        cursor: pointer;
        text-decoration: none;
        position: absolute;
        left: 5%;
        color: #95bace;
        font-size: 20px;
        top: 5px;
        font-weight: 700;
      }
      .plus-btn {
        cursor: pointer;
        text-decoration: none;
        position: absolute;
        right: 5%;
        color: #95bace;
        font-size: 20px;
        top: 5px;
        font-weight: 700;
      }
    }
    .cart .round {
      background: #eeeeee;
      color: #888888;
      padding: 22px;
      border-radius: 100%;
      position: relative;
      max-width: 45px;
      .cart-img {
        height: 23px;
        width: 20px;
        position: absolute;
        top: 10px;
        left: 12px;
        background-image: url("../../../assets/images/cart.svg");
      }
    }
  }
  .card-buttons .cart .round:hover {
    background: #cf0000;
    color: #fff;
    .cart-img {
      background: url("../../../assets/images/cartWhite.svg");
    }
  }
}

.tabs {
  .nav-pills {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 18px;
    .nav-item {
      position: relative;
      .nav-link {
        color: #95bace;
      }
      .nav-link.active {
        background: none;
        color: #365a6e;
        text-decoration: underline;
        font-weight: 700;
      }
    }
  }
}
.tab-content {
  .description {
    color: #627a86;
    font-size: 14px;
  }
}
.related-products {
  .text {
    background: #f6fafb;
    padding: 12px;
    color: #365a6e;
    margin-bottom: 20px;
    font-weight: 600;
  }
}
// .best-sellers {
//     background: #F6FAFB;
//     .container {
//         background: #fff;
//         .title {
//             color: #365A6E;
//         }
//     }
// }
</style>
<template>
  <div>
    <div class="loading" v-if="this.$store.getters.loading">
      <div class="spinner-grow text-success"></div>
    </div>

    <div v-else-if="allData != false">
      <Categories />
      <div class="container pt-4 pb-4">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="#">Home</a></li>
            <li class="breadcrumb-item"><a href="#">Shop</a></li>
            <li class="breadcrumb-item active" aria-current="page">
              {{ allData.product[0].Product }}
            </li>
          </ol>
        </nav>

        <div class="row product">
          <div class="col-md-6">
            <div
              id="carousel-thumb"
              class="carousel slide carousel-fade carousel-thumbnails"
              data-ride="carousel"
            >
              <!--Slides-->
              <div class="carousel-inner" role="listbox">
                <div
                  class="carousel-item"
                  :class="{ ' active ': index === 0 }"
                  v-for="(item, index) in allData.product[0].Fotoja"
                  v-bind:key="index"
                >
                  <img class="d-block w-100" :src="item" alt="First slide" />
                </div>
              </div>
              <!--/.Slides-->
              <!--Controls-->
              <a
                class="carousel-control-prev"
                href="#carousel-thumb"
                role="button"
                data-slide="prev"
              >
                <span
                  class="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span class="sr-only">Previous</span>
              </a>
              <a
                class="carousel-control-next"
                href="#carousel-thumb"
                role="button"
                data-slide="next"
              >
                <span
                  class="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span class="sr-only">Next</span>
              </a>
              <!--/.Controls-->
              <ol class="carousel-indicators">
                <li
                  data-target="#carousel-thumb"
                  :data-slide-to="index"
                  v-for="(item, index) in allData.product[0].Fotoja"
                  v-bind:key="index"
                  :class="{ ' active ': index === 0 }"
                >
                  <img class="img-fluid d-block w-100" :src="item" />
                </li>
              </ol>
            </div>
          </div>
          <div class="col-md-6">
            <h2 class="title">{{ allData.product[0].Product }}</h2>
            <div class="prices d-flex" v-if="currentUrl == 'shop'">
              <p
                class="price mr-2"
                v-if="typeof allData.product[0].discount !== 'undefined'"
              >
                <s>{{ allData.product[0].RegularPrice }}&euro;</s>
              </p>
              <h4 class="discount-price">
                {{ allData.product[0].RetailPrice }}&euro;
              </h4>
            </div>
            <div class="d-flex mt-2">
              <span class="fa fa-star checked"></span>
              <span class="fa fa-star checked"></span>
              <span class="fa fa-star checked"></span>
              <span class="fa fa-star"></span>
              <span class="fa fa-star mr-3"></span>
              <p class="reviews mb-0">{{allData.product[0].reviews}} reviews</p>
            </div>
            <hr />
            <div class="main-description" v-html="allData.product[0].description">
            </div>

            <div class="card-buttons mt-5">
              <div class="d-flex">
                <div class="col-7 pl-0">
                  <div class="quantity">
                    <a
                      @click="value > 1 ? changeQuantity('minus') : ''"
                      class="minus-btn"
                      >−</a
                    >
                    <input
                      disabled
                      class="form-input w-100 input-shop"
                      v-model="value"
                    />
                    <a @click="changeQuantity('plus')" class="plus-btn">+</a>
                  </div>
                </div>
                <div class="col-2 text-right pl-0 cart">
                  <a
                    class="add-to-cart"
                    @click="addToCart(allData.product[0].ID)"
                  >
                    <div
                      class="round"
                      :class="
                        allData.product[0].isInCart || isInCart ? 'active' : ''
                      "
                    >
                      <div class="cart-img"></div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="tabs border-top border-bottom">
        <div class="container">
          <ul
            class="nav nav-pills justify-content-between"
            id="pills-tab"
            role="tablist"
          >
            <li class="nav-item">
              <a
                class="nav-link active"
                id="pills-home-tab"
                data-toggle="pill"
                href="#pills-home"
                role="tab"
                aria-controls="pills-home"
                aria-selected="true"
                >Description</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                id="pills-profile-tab"
                data-toggle="pill"
                href="#pills-profile"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="false"
                >Additional information</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                id="pills-contact-tab"
                data-toggle="pill"
                href="#pills-contact"
                role="tab"
                aria-controls="pills-contact"
                aria-selected="false"
                >Review</a
              >
            </li>
          </ul>
        </div>
      </div>

      <div class="tab-content container mt-5 mb-5" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="pills-home"
          role="tabpanel"
          aria-labelledby="pills-home-tab"
        >
          <p class="description">
            Mid century modern plant stand, Inspired by the 1950's... this
            beautiful mid century style plant stand is the perfect decor piece
            for any room. Made from locally sourced wood.The stands have been
            stress-tested at over 100LBS to ensure it can withstand the weight
            of even your heaviest plant friend.<br /><br />
            Width - Select the width from the dropdown based on your pot size
            (For example, if you have a 12" pot, order the 12" width as we add a
            quarter of an inch so you have some wiggle room). Please note the
            grain & colour of the wood will vary slightly. All of our products
            are hand made, they are not mass produced. It's what makes each
            stand unique.<br /><br />
            The images used on the listing are a representation of the item and
            do not feature the exact item that will ship.If you have any
            questions regarding this item, please hit the “Ask a Question”
            button and we will get back to you within 24 hours.
          </p>
        </div>
        <div
          class="tab-pane fade"
          id="pills-profile"
          role="tabpanel"
          aria-labelledby="pills-profile-tab"
        ></div>
        <div
          class="tab-pane fade"
          id="pills-contact"
          role="tabpanel"
          aria-labelledby="pills-contact-tab"
        >
          ...
        </div>
      </div>

      <div class="related-products container pb-5">
        <div class="text">
          <span>Produkte te blera se bashku</span>
        </div>
        <div class="row">
          <Product
            :root="currentUrl"
            colLg="3"
            colMd="4"
            :data="product"
            v-for="product in allData.relatedProducts"
            :key="product.id"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { url, authHeader } from "../../../url.js";
import Categories from "../../layout/Categories.vue";
import Product from "../../layout/Product";

export default {
  data() {
    return {
      value: 1,
      slug: this.$route.params.slug,
      allData: false,
      isInCart: false,
      addedToCart: false,
      currentUrl: this.$route.name.toLowerCase().includes("shop")
        ? "shop"
        : "web",
      activeClass: "active",
    };
  },
  beforeMount() {
    this.getProduct(this.slug);
  },
  mounted() {
    window.scrollTo(0, 0);

    if (!this.$store.getters.logged) {
      if (typeof this.$store.getters.cart.card !== "undefined") {
        var cartData = this.$store.getters.cart.card;

        for (var i = 0; i < cartData.length; i++) {
          if (cartData[i].ID === this.data.ID) {
            this.isInCart = i;
            this.quantity = cartData[i].quantity;
          }
        }
      }
    } else {
      this.isInCart =
        typeof this.allData.isInCart !== "undefined" && this.allData.isInCart
          ? true
          : null;
    }
  },
  // beforeRouteUpdate (to, from, next) {
  //     // react to route changes...
  //     // don't forget to call next()
  //     this.$store.commit('changeLoading', true)
  //     this.slug = to.params.slug
  //     this.getProduct(to.params.slug)
  //     next()
  // },
  methods: {
    getProduct(slug) {
      this.$axios
        .get(url + `product?id=${slug}`)
        .then((res) => {
          this.allData = res.data;
          res.data.product[0].isInCart
            ? (value = this.$store.getters.cart.card.find(
                (x) => x.ID === allData.product[0].ID
              ).quantity)
            : (value = 1);
          this.$store.commit("changeLoading", false);
        })
        .catch((error) => {
          this.$store.commit("changeLoading", false);
        });
    },
    changeQuantity(action) {
      console.log(action);
      if (action == "minus") this.value = this.value - 1;
      else this.value = this.value + 1;
    },
    addToCart(product_id) {
      let data = {
        quantity: this.value,
        variation_id: 0,
        product_id: product_id,
      };

      this.$axios
        .post(url + "addToCart", data, { headers: { ...authHeader() } })
        .then((res) => {
          this.isInCart = true;
          this.$store.commit("fillCart", res.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  components: {
    Categories,
    Product,
  },
};
</script>
