<style lang="scss" scoped>
.mapouter {
  position: relative;
  text-align: right;
  width: 100%;
  height: 500px;
  .gmap_canvas {
    overflow: hidden;
    background: none !important;
    width: 100%;
    height: 100%;
    #gmap_canvas {
      width: 100%;
      height: 100%;
    }
  }
}

.title {
  color: #365a6e;
}

.send-message {
  background: #007fcf;
  color: #fff;
  border-radius: 0;
  font-size: 14px;
  width: 70%;
  padding: 10px;
}

.text {
  margin: 0;
  color: #627a86;
  font-size: 14px;
}

.grey-input {
  background: #f6f6f6;
  border: 1px solid #eeeeee;
  border-radius: 0;
  padding: 20px;
}

label {
  color: #627a86;
  font-size: 14px;
}

@media (min-width: 768px) {
  .contact {
    padding-left: 10%;
  }
}
</style>
<template>
  <div>
    <div class="mapouter">
      <div class="gmap_canvas">
        <iframe
          id="gmap_canvas"
          src="https://maps.google.com/maps?q=shkel%20e%20shko&t=&z=13&ie=UTF8&iwloc=&output=embed"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
        ></iframe>
        <a href="https://putlocker-is.org"></a>
      </div>
    </div>

    <div class="container pt-5 pb-5">
      <div class="row">
        <div class="col-md-12">
          <h1 class="title mb-5"><u>Na kontaktoni</u></h1>
        </div>

        <div class="col-md-6">
          <form>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label>Your Name</label>
                <input type="text" class="form-control grey-input" />
              </div>
              <div class="form-group col-md-6">
                <label>Your Email</label>
                <input type="email" class="form-control email grey-input" />
              </div>
            </div>
            <div class="form-group">
              <label>Subject</label>
              <input
                type="text"
                name="subject"
                class="form-control grey-input"
              />
            </div>
            <div class="form-group">
              <label>Message</label>
              <textarea
                class="message form-control grey-input"
                cols="30"
                rows="10"
              ></textarea>
            </div>
            <button class="btn send-message">SEND MESSAGE</button>
          </form>
        </div>

        <div class="col-md-6 contact mt-4">
          <h5 class="title mb-2">Kontakti</h5>
          <p class="text">Dardania SU 1/5-1-12 - 10000 Prishtina, Kosovo</p>

          <p class="text">info@shkeleshko.com,</p>
          <p class="text">www.shkeleshko.com</p>

          <h5 class="title mt-5 mb-2">Orari i punës</h5>
          <p class="text">Hëne – Premte : 09:00 – 20:00</p>
          <p class="text">Shtune: 10:30 – 22:00</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { url, authHeader } from "../../url";

export default {
  data() {
    return {
      value: 1,
      addedToCart: false,
    };
  },
  methods: {},
};
</script>