<style scoped>
.bg {
    background-image: url("../../assets/images/homepage.png");
    height: 100vh;
    width: 100vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.buttons {
    position: relative;
    top: 30%;
}

.green {
    background: #8FCF00;
}

.blue {
    background: #00236F;
}

a {
    color: #fff;
    text-decoration: none;    
}

.green.btn, .blue.btn {
    border-radius: 50px;
    font-size: 25px;
    font-weight: 600;
}

.blue.btn {
    padding: 15px 50px;
}
.green.btn {
    padding: 15px 35px;
}


#homepagelogo img{
    width: 300px;
}
@media (max-width: 1199px) {

}
</style>

<template>
    <div class="bg">
        <img src="" alt="">
        <div class="buttons container">
            <div class="row justify-content-center">
                <div class="col-md-12 text-center mb-5" id="homepagelogo">
                    <img src="@/assets/images/homepagelogo.svg">
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-lg-3 col-md-2"></div>
                <div class="col-lg-6 col-md-8 d-flex justify-content-between">
                    <router-link class="green btn" to="/shop">E-SHOP</router-link>
                    <router-link class="blue btn" to="/web">WEB</router-link>
                </div>
                <div class="col-lg-3 col-md-2"></div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
        }
    },
    components: {
    
    },
    methods: {}
};
</script>
