export const url = 'https://api.shkeleshko.com/wp-json/general/';
export const urlWeb = 'https://api.shkeleshko.com/wp-json/web/';
export const urlShop = 'https://api.shkeleshko.com/wp-json/shop/';
export const loginUrl = 'https://api.shkeleshko.com/wp-json/jwt-auth/v1/token';
export const base_url = 'http://127.0.0.1:8000/';

export function authHeader() {
    const token = localStorage.getItem('token')
    return { 'Authorization': 'Bearer ' + token }
}
