<style lang="scss">
.no-results {
  height: 70vh;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
</style>
<style scoped>
#main {
  position: relative;
  /* height: 100%; */
}
</style>
<template>
  <div id="main">
    <Navbar />
    <MainContent />
    <Footer />
  </div>
</template>

<script>
import { urlWeb } from "../../../url";
import MainContent from "./MainContent.vue";
import Navbar from "./Navbar.vue";
import Footer from "../Footer.vue";

export default {
  beforeCreate() {
    this.$axios
      .get(urlWeb + "allCategories")
      .then((res) => {
        this.$store.commit("fillCategories", res.data.categories);
        this.$store.commit("fillBizneset", res.data.bizneset);
        this.$store.commit("changeLoading", false);
      })
      .catch((e) => {});
  },
  data() {
    return {};
  },
  components: {
    Navbar,
    Footer,
    MainContent,
  },
  methods: {},
};
</script>
