<style lang="scss" scoped>
img {
  width: 100%;
  height: auto;
  object-fit: contain;
}
</style>
<template>
  <div class="">
    <img :src="require(`../../../assets/images/soon1.png`)" />
  </div>
</template>
<script>
export default {};
</script>