<style lang="scss" scoped>
// ********ALL PRODUCTS SECTION*********
.all-products {
  background: #8fcf0033;
  height: 120px;
  .title {
    font-weight: 600;
    color: #00236f;
  }
}

.blueBackground {
  color: white;
  background-color: #007fcf !important;
}

.show-more a {
  font-weight: 700;
  color: #000;
}
</style>
<template>
  <div>
    <div class="loading" v-if="$store.getters.loading">
      <div class="spinner-grow text-success"></div>
    </div>

    <div v-else>
      <!-- <div
        class="
          row
          m-0
          all-products
          text-center
          d-flex
          text-white
          align-content-center
        "
      >
        <div class="col-md-12">
          <h3 class="title">Të gjitha produktet</h3>
        </div>
      </div> -->

      <Categories />

      <div class="container pt-5 pb-4 pl-sm-0 pr-sm-0">
        <div class="category-products">
          <div class="row">
            <!-- <Product
              :root="currentUrl"
              colLg="3"
              colMd="4"
              :data="product"
              v-for="product in products"
              :key="product.id"
            /> -->
          </div>
        </div>
        <div
          v-if="this.maxNumberOfPages > this.page"
          class="text-center show-more mt-5"
        >
          <button
            class="btn blueBackground"
            v-if="!loadingMore"
            @click="loadMoreProducts()"
          >
            Shfaq më shumë
          </button>
          <button v-else class="btn blueBackground" type="button" disabled>
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span class="sr-only">Loading...</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Product from "../layout/Product";
import Categories from "../layout/Categories";
import { url } from "../../url.js";
import defaultImage from "@/assets/images/paisje.svg";

export default {
  data() {
    return {
      categories: [],
      activeCategory: this.$route.params.category,
      page: 1,
      perPage: 20,
      loadingMore: false,
      maxNumberOfPages: 0,
      products: [],
      currentUrl: this.$route.name.toLowerCase().includes("shop")
        ? "shop"
        : "web",
    };
  },
  beforeCreate() {},
  beforeMount() {
    window.scrollTo(0, 0);
    this.getProducts(this.activeCategory, this.page);
  },
  beforeDestroy() {
    localStorage.removeItem(`shkeleShkoSubCategories`);
  },
  components: {
    Product,
    Categories,
  },
  methods: {
    getProducts(category, page) {
      this.$store.commit("changeLoading", true);

      let data = {
        minPrice: 0,
        maxPrice: 19999999,
        search: "",
        order: "name",
        page: this.page,
        perPage: this.perPage,
      };

      this.$axios
        .post(url + "baesearch", data)
        .then((res) => {
          this.products = res.data.products;
          this.maxNumberOfPages = res.data.maxNumberOfPages;
          this.$store.commit("changeLoading", false);
        })
        .catch((error) => {
          this.$store.commit("changeLoading", false);
        });
    },
    loadMoreProducts(category, page) {
      this.page++;
      this.loadingMore = true;
      let data = {
        minPrice: 0,
        maxPrice: 19999999,
        search: "",
        order: "name",
        page: this.page,
        perPage: this.perPage,
      };

      this.$axios
        .post(url + "baesearch", data)
        .then((res) => {
          var currentProducts = this.products;
          var newProducts = res.data.products;
          this.products = currentProducts.concat(newProducts);
          this.maxNumberOfPages = res.data.maxNumberOfPages;
          this.loadingMore = false;
        })
        .catch((error) => {
          this.$store.commit("changeLoading", false);
        });
    },
    imgError(e) {
      e.target.src = defaultImage;
      // e.target.classList.toggle('img-error')
    },
  },
};
</script>
