<style lang="scss" scoped>
.add-to-cart {
  position: relative;
  .round {
    position: absolute;
    top: 5px;
    left: 7px;
    width: 30px;
    pointer-events: none;
    padding: 18px;
    border-radius: 100%;
    .cart-img {
      height: 23px;
      width: 20px;
      position: absolute;
      top: 5px;
      left: 7px;
      background-image: url("../../../assets/images/cartWhite.svg");
    }
  }
}
.banner-carousel {
  color: #fff;
  position: relative;
  .carousel-indicators {
    li {
      width: 10px;
      height: 10px;
      border-radius: 100%;
    }
    .active {
      background-color: #cf0000;
    }
  }
  .text {
    position: absolute;
    top: 45%;
    transform: translate(0%, -55%);
  }
  .intro {
    font-weight: 400;
  }
  .title {
    font-weight: bold;
    color: #fff;
    text-decoration: none;
    line-height: 1;
  }
  .description {
    color: #fff;
    font-weight: bold;
    line-height: 1;
  }
  .buy-now {
    margin-top: 20px;
    color: #fff;
    background: #8fcf00;
    border-radius: 100px;
    padding: 10px 30px;
    font-size: 14px;
  }
}

.oferta-javore {
  h5 {
    color: #00236f;
    font-weight: bold;
  }
  .oferta-javore-container {
    padding: 15px 5px;
    border: 2px solid rgba($color: #bbbbbb, $alpha: 0.3);
    .img-container {
      img {
        object-fit: contain;
        width: 300px;
        height: 337px !important;
      }
    }
    .text {
      padding-top: 15px;
      .category {
        color: #aaaaaa;
        font-size: 12px;
        margin: 0;
      }
      .title {
        color: #00236f;
      }
      .price {
        font-size: 1.25rem;
      }
    }
  }
  .cart {
    .quantity {
      position: relative;
      input {
        border: none;
        border-radius: 100px;
        padding: 5px;
        color: #365a6e;
        font-weight: 700;
        text-align: center;
        height: 100%;
      }
      .input-shop {
        background: #8fcf0033;
      }
      .input-web {
        background: #007fcf34;
      }
      .minus-btn {
        cursor: pointer;
        text-decoration: none;
        position: absolute;
        left: 5px;
        color: #95bace;
        font-size: 20px;
        top: 5px;
        font-weight: 700;
      }
      .plus-btn {
        cursor: pointer;
        text-decoration: none;
        position: absolute;
        right: 5px;
        color: #95bace;
        font-size: 20px;
        top: 5px;
        font-weight: 700;
      }
    }
    .add-to-cart {
      background: #8fcf00;
      border-radius: 100px;
      color: #fff;
      padding: 10px 0px;
      .cart-icon {
        float: left;
        padding-top: 5px;
        padding-left: 10px;
      }
    }
  }
}

.category-title {
  color: #00236f;
  font-size: 1.25rem;
  font-weight: bold;
}

.gradient-blue {
  background: linear-gradient(
    to right,
    #007fcf 0px,
    #007fcf 100px,
    rgba(0, 0, 0, 0.1) 100px,
    rgba(0, 0, 0, 0.1) 100%
  );
  height: 2px;
  margin-bottom: 2em;
}

.gradient-red {
  background: linear-gradient(
    to right,
    #cf0000 0px,
    #cf0000 100px,
    rgba(0, 0, 0, 0.1) 100px,
    rgba(0, 0, 0, 0.1) 100%
  );
  height: 2px;
  margin-bottom: 2em;
  margin-top: 1rem;
}

.filters {
  .filter {
    cursor: pointer;
    font-size: 1.25rem;
    color: #555555;
    margin-right: 30px;
    &.active {
      color: #000;
      font-weight: bold;
    }
  }
}

#ofertaIndicators {
  .carousel-control-next,
  .carousel-control-prev {
    width: 5%;
    .my-indicators {
      font-size: 35px;
      color: blue;
    }
  }
}
.category-products {
  .more {
    color: #007fcf;
    word-break: keep-all;
    font-weight: 600;
  }
}

.ad-product {
  position: relative;
  height: 400px;
  .img-container {
    height: 100%;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .text {
    position: absolute;
    top: 15%;
    padding-left: 15px;
    padding-right: 15px;
    p {
      margin: 0;
      line-height: 1.2;
    }
    .category {
      color: #888888;
      font-size: 18px;
    }
    .title {
      color: #f12a43;
      font-size: 1.75rem;
      font-weight: 600;
    }
    .discount-price {
      color: #f12a43;
      font-size: 1.75rem;
      font-weight: bold;
    }
    .price {
      color: #888888;
      font-weight: 700;
      font-size: 1.25rem;
    }
  }
}

@media (min-width: 768px) {
  .ad-product {
    height: unset !important;
  }
  .oferta-javore-container {
    .img-container {
      img {
        height: unset !important;
      }
    }
  }
  .oferta-javore {
    .oferta-javore-container {
      margin: 0;
    }
    .cart {
      position: absolute;
      right: 20px;
      bottom: 0;
    }
  }
}
@media (max-width: 768px) {
  .oferta-javore-container {
    .img-container {
      img {
        width: 100% !important;
      }
    }
  }
  .my-col-2 {
    flex-basis: auto;
  }
  .oferta-javore {
    .row {
      margin: 0;
    }
  }

  .banner-carousel {
    .text {
      display: flex;
      justify-content: space-between;
      .text-inner {
        width: 65vw;
      }
      .button {
        width: 25vw;
      }
      .intro {
        font-size: 16px;
      }
      .title {
        font-size: 20px;
      }
      .description {
        font-size: 20px;
      }
      .buy-now {
        padding: 5px 10px;
      }
    }
  }
}
.filters .filter {
  font-size: 1rem !important;
}
// ********CATEGORIES SECTION*********
.categories {
  .icon-1 {
    width: 20px;
  }
  .icon {
    width: 40px;
  }
  .items {
    .active-red-border-bottom {
      border-bottom: 1px solid #cf0000 !important;
    }
    .item {
      padding: 20px;
      height: 120px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      h6 {
        color: #00236f;
        font-weight: 600;
      }
    }
  }
}

.show-more a {
  font-weight: 700;
  color: #000;
}

@media (min-width: 768px) {
  .categories {
    .items {
      .item {
        border-right: 1px solid #e2e2e2;
      }
      .item:last-child {
        border-right: none;
      }
    }
  }
}

@media (max-width: 500px) {
  .banner-carousel {
    .text {
      .buy-now {
        margin: 5px 0px !important;
        font-size: 10px;
      }
      .intro {
        margin: 5px 0px !important;
        font-size: 12px;
      }
      .title {
        margin: 5px 0px !important;
        font-size: 14px;
      }
      .description {
        margin: 5px 0px !important;
        font-size: 12px;
      }
    }
  }
}
</style>
<template>
  <div>
    <div class="loading" v-if="$store.getters.loading">
      <div class="spinner-grow text-success"></div>
    </div>

    <div v-else>
      <Categories :hideSubCategories="true" />

      <div
        id="bannerIndicators"
        class="carousel slide banner-carousel"
        data-ride="carousel"
      >
        <ol class="carousel-indicators">
          <li
            data-target="#bannerIndicators"
            class="active"
            data-slide-to="0"
          ></li>
          <!-- <li data-target="#bannerIndicators" v-for="(baneri, index) in baneriKryesor" :key="baneri.ID" :class="{ 'active': index === 0 }" :data-slide-to="index"></li> -->
        </ol>
        <!-- <picture class="d-block w-100">
                    <source 
                        srcset="@/assets/images/1920x665.png"
                        media="(max-width: 1920px)"
                    />
                    <source 
                        srcset="@/assets/images/768x265.png"
                        media="(min-width: 768px)"
                    />
                    <source 
                        srcset="@/assets/images/375x130.png"
                        media="(max-width: 768px)"
                    />
                    <img 
                        src="@/assets/images/1920x665.png" 
                        alt="Baneri"
                    />
                </picture> -->
        <div class="carousel-inner">
          <div class="carousel-item active" data-slide-to="0">
            <picture class="d-block w-100">
              <source
                class="w-100"
                srcset="@/assets/images/1920x665.png"
                media="(min-width: 991px)"
              />
              <source
                class="w-100"
                srcset="@/assets/images/768x265.png"
                media="(max-width: 991px)"
              />
              <source
                class="w-100"
                srcset="@/assets/images/375x130.png"
                media="(max-width: 450px)"
              />
              <img
                class="w-100"
                src="@/assets/images/1920x665.png"
                alt="Baneri"
              />
            </picture>
          </div>
          <!-- <div class="carousel-item" v-for="(baneri, index) in baneriKryesor" :key="baneri.ID" :class="{ 'active': index === 0 }" :data-slide-to="index">
                        <img class="d-block w-100" :src="baneri.Fotoja" alt="First slide">
                        
                        <div class="container">
                            <div class="text">
                                <div class="text-inner">
                                    <h5 class="intro">Find the right washer for you</h5>
                                    <h2 class="title">{{ baneri.Product }}</h2>
                                    <h2 class="description mt-3">{{ baneri.RetailPrice }}€</h2>
                                </div>
                                <div class="button">
                                    <router-link :to="{ name: 'ProductDetailsshop', params: { 'slug': baneri.ID }}" class="btn buy-now">Shiko produktin</router-link>
                                </div>
                            </div>
                        </div>
                    </div> -->
        </div>
      </div>

      <div class="container pt-5 pb-5">
        <div class="row">
          <router-link
            :to="{
              name: 'ProductDetailsshop',
              params: { slug: ofertaJavore2.ID },
            }"
            class="col-lg-3 col-md-5 ad-product"
            v-if="ofertaJavore2 && typeof ofertaJavore2.Fotoja !== 'undefined'"
          >
            <div class="img-container">
              <picture>
                <source
                  srcset="@/assets/images/450.png"
                  media="(max-width: 768px)"
                />
                <source
                  srcset="@/assets/images/255x420.png"
                  media="(min-width: 991px)"
                />
                <source
                  srcset="@/assets/images/270x570.png"
                  media="(min-width: 768px)"
                />
                <img src="@/assets/images/255x420.png" alt="Oferta Javore" />
              </picture>
              <!-- <img srcset="@/assets/images/450.png 1x, @/assets/images/255x420.png 2x" src="@/assets/images/255x420.png"> -->
            </div>
            <!-- <div class="text">
                                <p class="category">{{ ofertaJavore2.Categories }}</p>
                                <p class="title">{{ ofertaJavore2.Product }}</p>
                                <p v-if="typeof ofertaJavore2.discount === 'undefined'" class="discount-price mt-1">{{ ofertaJavore2.RetailPrice }}&euro;</p>
                                <div v-else>
                                    <p class="discount-price">{{ ofertaJavore2.RegularPrice }}&euro;</p>
                                    <h6 class="price text-muted"><s><small>{{ ofertaJavore2.RetailPrice }}&euro;</small></s></h6>
                                </div>
                            </div> -->
          </router-link>
          <div class="col-lg-9 col-md-7 oferta-javore">
            <div class="row m-0">
              <div class="col-md-12 p-0">
                <h5>OFERTA JAVORE</h5>
              </div>
            </div>

            <div
              id="ofertaIndicators"
              class="carousel slide"
              data-ride="carousel"
            >
              <div class="carousel-inner">
                <div
                  class="carousel-item"
                  :class="index == 0 ? 'active' : ''"
                  v-for="(product, index) in ofertaJavore"
                  :key="product.ID"
                >
                  <div class="oferta-javore-container row mt-2">
                    <router-link
                      :to="{
                        name: 'ProductDetailsshop',
                        params: { slug: product.ID },
                      }"
                      class="img-container col-lg-7"
                    >
                      <img
                        v-if="typeof product.Fotoja !== 'undefined'"
                        :src="product.Fotoja[0]"
                      />
                    </router-link>
                    <div class="col-lg-5 text">
                      <p class="category">{{ product.Categories }}</p>
                      <h3 class="title">{{ product.Product }}</h3>
                      <p
                        class="price text-danger font-weight-bold"
                        v-if="typeof product.discount !== 'undefined'"
                      >
                        {{ product.RetailPrice }}&euro;
                        <span class="text-muted"
                          ><s
                            ><small>{{ product.RegularPrice }}&euro;</small></s
                          ></span
                        >
                      </p>
                      <p class="price text-danger font-weight-bold" v-else>
                        {{ product.RetailPrice }}&euro;
                      </p>
                      <p>
                        Disponueshmeria:
                        <span
                          class="text-success"
                          v-if="parseInt(product.StockQuantity) > 0"
                          >Në stok</span
                        >
                        <span class="text-danger" v-else>Në stok</span>
                      </p>

                      <div class="cart d-flex">
                        <a
                          class="btn add-to-cart w-75"
                          @click="addToCart(product.ID, product)"
                        >
                          <div class="round">
                            <div class="cart-img"></div>
                          </div>
                          {{
                            product.isInCart ||
                            (typeof $store.getters.cart.card !== "undefined" &&
                              $store.getters.cart.card.find(
                                (cartData) => cartData.ID === product.ID
                              )) ||
                            addedToCart
                              ? "Është në shportë"
                              : "Shto në shportë"
                          }}
                        </a>
                        <!-- <a class="add-to-cart" @click="addToCart(product.ID)"> 
                                                        <div class="round" :class="product.isInCart || (typeof $store.getters.cart.card !== 'undefined' && $store.getters.cart.card.find(cartData => cartData.ID === product.ID)) || addedToCart ? 'active': ''">
                                                            <div class="cart-img">
                                                            </div>
                                                        </div>
                                                    </a>  -->
                        <div class="quantity w-25 ml-2">
                          <a
                            @click="value > 1 ? changeQuantity('minus') : ''"
                            class="minus-btn"
                            >−</a
                          >
                          <input
                            disabled
                            class="form-input w-100 input-shop"
                            v-model="value"
                          />
                          <a @click="changeQuantity('plus')" class="plus-btn"
                            >+</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <a
                class="carousel-control-prev"
                href="#ofertaIndicators"
                role="button"
                data-slide="prev"
              >
                <i class="fas fa-caret-left my-indicators"></i>
                <span class="sr-only">Previous</span>
              </a>
              <a
                class="carousel-control-next"
                href="#ofertaIndicators"
                role="button"
                data-slide="next"
              >
                <i class="fas fa-caret-right my-indicators"></i>
                <span class="sr-only">Next</span>
              </a>
            </div>
          </div>
          <div class="w-100 mt-5 col-md-12">
            <div class="d-flex filters">
              <a
                class="filter"
                :class="activeAccordion == 'offers' ? 'active' : ''"
                @click="changeActiveAccordion('offers')"
                >Ofertat</a
              >
              <a
                class="filter"
                :class="activeAccordion == 'newest' ? 'active' : ''"
                @click="changeActiveAccordion('newest')"
                >Të rejat</a
              >
              <a
                class="filter"
                :class="activeAccordion == 'bestSellers' ? 'active' : ''"
                @click="changeActiveAccordion('bestSellers')"
                >Më të shiturat</a
              >
            </div>
            <div class="gradient-red"></div>

            <div class="category-products mb-4 row">
              <Product
                root="shop"
                colLg="3"
                colMd="4"
                :data="product"
                v-for="product in activeAccordionData"
                :key="product.id"
              />
            </div>

            <div
              class="category-products mb-4 row"
              v-for="category in categoriesProducts"
              :key="category.ID"
            >
              <div class="col-md-12">
                <div class="d-flex justify-content-between">
                  <h4 class="category-title">{{ category.name }}</h4>
                  <router-link
                    :to="{
                      name: 'CategoryProductsShop',
                      params: { category: category.slug, page: 1 },
                    }"
                    class="more"
                    >me shume</router-link
                  >
                </div>
                <div class="gradient-blue"></div>
              </div>
              <Product
                root="shop"
                colLg="3"
                colMd="4"
                :data="product"
                v-for="product in category.products"
                :key="product.ID"
              />
            </div>
          </div>
          <!-- <h6 class="text-center w-100">Shfaq me shume </h6> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Product from "../../layout/Product.vue";
import Categories from "../../layout/Categories";
import { url, urlShop, authHeader } from "../../../url";
import defaultImage from "@/assets/images/paisje.svg";

export default {
  data() {
    return {
      value: 1,
      baneriKryesor: [],
      categoriesProducts: [],
      accordionPost: [],
      activeAccordion: "offers",
      activeAccordionData: [],
      categories: [],
      ofertaJavore: [],
      ofertaJavore2: [],
      addedToCart: false,
      data: [],
    };
  },
  mounted() {
    localStorage.removeItem(`shkeleShkoSubCategories`);
    if (this.$store.getters.logged) {
      this.$axios
        .get(urlShop + "firstPageRequest", { headers: { ...authHeader() } })
        .then((res) => {
          this.baneriKryesor = res.data.baneriKryesor;
          this.categoriesProducts = res.data.categoriesProducts;
          this.accordionPost = res.data.accordionPost;
          this.activeAccordionData = res.data.accordionPost["offers"];
          this.ofertaJavore = res.data.ofertaJavore.products;
          console.log(res.data.anashOfertesJavore,'res.data.anashOfertesJavore123')
          this.ofertaJavore2 = res.data.anashOfertesJavore[0];
          this.$store.commit("changeLoading", false);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.$axios
        .get(urlShop + "firstPageRequest")
        .then((res) => {
          this.baneriKryesor = res.data.baneriKryesor;
          this.categoriesProducts = res.data.categoriesProducts;
          this.accordionPost = res.data.accordionPost;
          this.ofertaJavore = res.data.ofertaJavore.products;
          this.ofertaJavore2 = res.data.anashOfertesJavore[0];
          console.log(res.data.anashOfertesJavore,'res.data.anashOfertesJavore321')

          this.activeAccordionData = res.data.accordionPost["offers"];
          this.$store.commit("changeLoading", false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  methods: {
    changeQuantity(action) {
      if (action == "minus") this.value = this.value - 1;
      else this.value = this.value + 1;

      if (this.$store.getters.logged && this.addedToCart) {
        let data = {
          quantity: this.value,
          variation_id: 0,
          product_id: this.data.ID,
        };

        this.$axios
          .post(url + "addToCart", data, { headers: { ...authHeader() } })
          .then((res) => {
            this.addedToCart = true;
            this.$store.commit("fillCart", res.data);
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        if (this.$store.getters.cart == false && this.addedToCart) {
          this.data.quantity = this.value;

          let cartData = {
            shipping: 0,
            card: [this.data],
            total: this.data.RetailPrice,
          };

          localStorage.setItem("cart", JSON.stringify(cartData));
          this.$store.commit("fillCart", cartData);
        } else if (this.addedToCart) {
          let cart = this.$store.getters.cart;
          let found = false;

          for (const [key, value] of Object.entries(cart.card)) {
            if (value.ID == this.data.ID) found = key;
          }

          if (found != false) {
            this.data.quantity = this.value;
            cart.card[found] = this.data;
            if (this.data.quantity == this.quantity) {
              var quantity = this.quantity;
            } else if (this.data.quantity == this.quantity) {
              var quantity = this.data.quantity - this.quantity;
            }

            cart.card[found].quantity = this.quantity;
            cart.total += quantity * this.data.RetailPrice;
          } else {
            cart.card.push(this.data);
          }

          this.$store.commit("fillCart", cart);
          localStorage.setItem("cart", JSON.stringify(cart));
        }
      }
    },
    addToCart(product_id, product) {
      let data = {
        quantity: this.value,
        variation_id: 0,
        product_id: product_id,
      };

      if (this.$store.getters.logged) {
        this.$axios
          .post(url + "addToCart", data, { headers: { ...authHeader() } })
          .then((res) => {
            this.addedToCart = true;
            this.$store.commit("fillCart", res.data);
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        if (this.$store.getters.cart != false) {
          this.data.quantity = this.value;
          this.data = product;

          let cartData = {
            shipping: 0,
            card: [this.data],
            total: this.data.RetailPrice,
          };

          localStorage.setItem("cart", JSON.stringify(cartData));
          this.$store.commit("fillCart", cartData);
        } else {
          let cart = this.$store.getters.cart;
          let found = false;

          for (const [key, value] of Object.entries(cart.card)) {
            if (value.ID == this.data.ID) found = key;
          }

          if (found != false) {
            this.data.quantity = this.value;
            cart.card[found] = this.data;
            if (this.data.quantity == this.quantity) {
              var quantity = this.quantity;
            } else if (this.data.quantity == this.quantity) {
              var quantity = this.data.quantity - this.quantity;
            }

            cart.card[found].quantity = this.quantity;
            cart.total += quantity * this.data.RetailPrice;
          } else {
            cart.card.push(this.data);
          }

          this.$store.commit("fillCart", cart);
          localStorage.setItem("cart", JSON.stringify(cart));
        }
      }
    },
    changeActiveAccordion(accordion) {
      this.activeAccordion = accordion;
      this.activeAccordionData = this.accordionPost[accordion];
    },
    imgError(e) {
      e.target.src = defaultImage;
      // e.target.classList.toggle('img-error')
    },
  },
  components: {
    Product,
    Categories,
  },
};
</script>
