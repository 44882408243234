<style lang="scss" scoped>
// ********CATEGORIES SECTION*********
.categories {
  .right-arrow {
    float: right;
  }
  .icon {
    width: 30px;
  }
  .items {
    //overflow-x: scroll;
    overflow-y: hidden;
    flex-wrap: wrap;
    gap: 1px;
    justify-content: space-between;
    align-items: center;
    .item.active {
      border-bottom: 1px solid #cf0000 !important;
    }
    .item {
      //border: 1px solid #e2e2e2;
      display: block;
      cursor: pointer;
      padding: 20px 5px;
      height: 110px;
      min-width: 100px;
      width: 100px;
    flex-basis: calc(100% / 9) !important;
      .img-container {
        height: 30px;
      }
      .icon {
        filter: grayscale(1);
      }
      &:hover,
      &.active {
        .icon {
          filter: grayscale(0);
        }
      }
      .text {
        color: #00236f;
        font-weight: 600;
        font-size: 12px;
        margin: 0;
      }
    }
    .item:last-child {
      //   border-right: none;
    }

    // .marginLeft {
    //   margin-left: 15px;
    // }
  }
}
.sub-category {
  font-size: 16px;
  padding: 0 15px;
  margin-right: 5px;
  border: solid 1px #8fcf00;
  border-radius: 20px;
  cursor: pointer;
  p {
    color: #00236f;
    margin: 0 !important;
  }
  &:hover {
    font-size: 16px;
    padding: 0 15px;
    margin-right: 5px;
    border: solid 1px #8fcf0033;
    border-radius: 20px;
    background-color: #8fcf00;
    font-weight: 500;
    p {
      color: #00236f;
      margin: 0 !important;
    }
  }
}
.goBackButton{
  width: 25px;
  height: 25px;
  background-color: #8fcf00;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: 200ms;
  &:hover{
    background-color: transparent;
    border: solid 2px #8fcf00;
  }
  svg{
    fill: #00236f;
    width: 100%;
    height: 100%;
    padding: 3px;
  }
}

@media (min-width: 768px) {
  .categories {
    .items {
      .item {
        // border-right: 1px solid #e2e2e2;
      }
    }
  }
}
</style>
<template>
  <div>
    <div class="categories">
      <div class="container text-center">
        <div>
          <div class="d-flex items">
            <div
              class="item"
              :class="category.slug == activeCategory ? 'active' : ''"
              v-for="category in $store.getters.categories"
              :key="category.id"
              @click="changeRoute(category)"
            >
              <img class="icon" :src="category.FotojaAktive" />
              <p class="text mt-2">{{ category.cat_name }}</p>
              <!-- <div class="img-container">
                            </div>
                            <div class="text-center">
                            </div> -->
            </div>
          </div>
          <div
            class="d-flex items justify-content-start"
            v-if="
              !hideSubCategories &&
              nenKategorite.length !== 0 &&
              nenKategorite[0].length !== 0
            "
          >
            <h4
              style="width: 100%; margin-top: 15px"
              v-if="nenKategorite.length !== 0"
            >
              Nenkategorite
            </h4>
            <div class="goBackButton" @click="goBack">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160zm352-160l-160 160c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L301.3 256 438.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0z"/></svg>
            </div>
            <div
              class="sub-category"
              :class="subcategory.slug == activeCategory ? 'active' : ''"
              v-for="subcategory in nenKategorite[0]"
              :key="subcategory.id"
              @click="changeRoute(subcategory, 'sub')"
            >
              <p class="text mt-2">{{ subcategory.cat_name }}</p>
            </div>
          </div>
          <div v-else>
            <div v-if="nenKategorite" class="goBackButton my-2" @click="goBack">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160zm352-160l-160 160c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L301.3 256 438.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0z"/></svg>
            </div>
          </div>
          <!-- <span v-if="nenKategorite[0] != null"></span>
          <span v-else>nuk u gjet asnje nenkategori</span> -->
        </div>

        <!--        <div v-else>-->
        <!--          <div-->
        <!--            class="d-flex items"-->
        <!--            v-if="-->
        <!--              typeof $store.getters.bizneset !== 'undefined' &&-->
        <!--                $route.name != 'CategoryProductsWeb'-->
        <!--            "-->
        <!--          >-->
        <!--            <div-->
        <!--              class="item"-->
        <!--              :class="category.slug == activeCategory ? 'active' : ''"-->
        <!--              v-for="category in $store.getters.bizneset"-->
        <!--              :key="category.id"-->
        <!--              @click="changeRoute(category)"-->
        <!--            >-->
        <!--              <img class="icon" :src="category.FotojaAktive" />-->
        <!--              <p class="text mt-2">{{ category.cat_name }}</p>-->
        <!--              &lt;!&ndash; <div class="img-container">-->
        <!--                            </div>-->
        <!--                            <div class="text-center">-->
        <!--                            </div> &ndash;&gt;-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
        <!-- <div class="row">
                    <div class="col-md-12 mt-2">
                        <i class="fas fa-long-arrow-alt-right right-arrow"></i>
                    </div>
                </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { url } from "../../url.js";

export default {
  props: ["subCategories", "hideSubCategories", 'selectedCategoryGeneratedTag'],
  data() {
    return {
      categories: [],
      activeCategory: this.$route.params.category,
      activeSubCategory: this.$route.params.subCategory,
      currentUrl: this.$route.name.toLowerCase().includes("shop")
        ? "shop"
        : "web",
      callProducts: false,
      nenKategorite: [],
    };
  },

  async mounted() {
    this.$nextTick();
    let localStorageSubCategories = localStorage.getItem(
      "shkeleShkoSubCategories"
    );
    if (localStorageSubCategories && localStorageSubCategories.length !== 0) {
      this.nenKategorite.push(JSON.parse(localStorageSubCategories));
    }
    this.findSubCategories();
  },
  methods: {
    goBack(){
      // this.$router.go(-1)
      let tempArray = [];

      this.$store.getters.categories.forEach((category)=>{
        tempArray.push({slug: `${category?.slug}#${category.cat_ID}`, parent: category})
          category.subcategories.length !== 0 && category.subcategories.forEach((subCategory)=>{
            tempArray.push({slug: `${category?.slug}#${category.cat_ID}$-$${subCategory?.slug}#${subCategory.cat_ID}` , parent: category})
              subCategory.subcategories.length !== 0 && subCategory.subcategories.forEach((subSubCategory)=>{
                tempArray.push({slug: `${category?.slug}#${category.cat_ID}$-$${subCategory?.slug}#${subCategory.cat_ID}$-$${subSubCategory?.slug}#${subSubCategory.cat_ID}`, parent: subCategory})
              })
          })
      })
      let foundCategory = tempArray.find((e)=>e.slug.includes(this.selectedCategoryGeneratedTag))

      let categoryPath = foundCategory.slug.split('$-$')

      let parentCategorySlug = ''

      let indexOfCategory = categoryPath.indexOf(this.selectedCategoryGeneratedTag)
      if(indexOfCategory === 0){
        parentCategorySlug = this.selectedCategoryGeneratedTag.split('#')[0]
      }else{
        parentCategorySlug = categoryPath[categoryPath.indexOf(this.selectedCategoryGeneratedTag) - 1].split('#')[0]
      }
      localStorage.setItem(
        "shkeleShkoSubCategories",
        JSON.stringify(foundCategory.parent.subcategories)
      );
      this.$router
        .replace({
          name:
            this.currentUrl === "shop"
              ? "CategoryProductsShop"
              : "CategoryProductsWeb",
          params: { category: parentCategorySlug, page: 1 },
        })
        .then((res) => {
          this.callProducts = true;
          this.$emit("callProducts", {
            call: this.callProducts,
            param: parentCategorySlug,
            subCategories: foundCategory.parent.subcategories,
          });
          window.scrollTo(0, 0);
        })
        .catch((error) => {});
    },
    findSubCategories() {
      let subs = this.$store.getters.categories.find(
        (x) => x.category_nicename === this.$route.params.category
      );

      if (subs) {
        this.nenKategorite.push(subs.subcategories);
      }
    },
    changeRoute(category, type) {
      // if (type === "sub") {
        localStorage.setItem(
          "shkeleShkoSubCategories",
          JSON.stringify(category?.subcategories)
        );
      // } else {
      // }
      // if (this.currentUrl == "shop") {
      //   this.$router
      //     .replace({
      //       name: "CategoryProductsShop",
      //       params: { category: category.slug, page: 1 },
      //     })
      //     .then((res) => {
      //       this.callProducts = true;
      //       this.$emit("callProducts", {
      //         call: this.callProducts,
      //         param: category.slug,
      //         subCategories: category.subcategories,
      //       });
      //       window.scrollTo(0, 0);
      //     })
      //     .catch((error) => {});
      // } else {
      //   this.$router
      //     .replace({
      //       name: "CategoryProductsWeb",
      //       params: { category: category.slug, page: 1 },
      //     })
      //     .then((res) => {
      //       window.scrollTo(0, 0);
      //     })
      //     .catch((error) => {});
      // }
      this.$router
        .replace({
          name:
            this.currentUrl === "shop"
              ? "CategoryProductsShop"
              : "CategoryProductsWeb",
          params: { category: category.slug, page: 1 },
        })
        .then((res) => {
          this.callProducts = true;
          this.$emit("callProducts", {
            call: this.callProducts,
            param: category.slug,
            subCategories: category.subcategories,
          });
          window.scrollTo(0, 0);
        })
        .catch((error) => {});
    },
  },
};
</script>
