<style lang="scss" scoped>
// ********BANNERS SECTION*********
.categoriesContainer {
  .fullWidthCategories {
    .container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .carousel-content {
        width: 10%;
      }
      @media screen and (max-width: 500px) {
        .carousel-content {
          width: 50%;
        }
      }
    }
  }
  .carousel-content {
    display: flex;
    flex-direction: column;
    img {
      width: 50%;
      height: auto;
      filter: grayscale(1);
      opacity: 0.7;
      transition: 200ms;
      margin: 10px 0;
    }
    &:hover {
      img {
        filter: grayscale(0);
        opacity: 1;
      }
    }

    .carousel-content-text {
      font-size: 12px;
    }
    .slick-prev,
    .slick-next {
      background: black !important;
    }
  }
}

.pb-5 {
  padding-bottom: 1rem !important;
}
.banner {
  position: relative;
  img {
    width: 100%;
    height: 100%;
    filter: blur(5px);
    object-fit: cover;
  }
  .text {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -55%);
  }
  h1.title {
    font-weight: bold;
    color: #fff;
  }
  h3.description {
    font-size: 24px;
    color: #e4e4e4;
  }
}

// ********OUR MISSION SECTION*********

.our-mission {
  .title {
    color: #00236f;
  }
  .first-description {
    color: #7d7d7d;
  }
  .second-description {
    border-left: 2px solid #007fcf;
    color: #007fcf;
    font-size: 16px;
  }
  .learn-more {
    margin-top: 20px;
    color: #fff;
    background: #007fcf;
    border-radius: 100px;
    padding: 12px 35px;
    font-size: 14px;
  }
}

// ********CATEGORIES SECTION*********

.categories {
  text-align: center;
  .title {
    color: #333333;
    margin-bottom: 1rem;
  }
  .icon {
    width: 60px;
  }
  .items {
    .item:nth-child(odd) {
      border-right: 1px solid #707070;
    }

    .item:nth-last-child(1):nth-child(odd),
    .item:last-child {
      border-bottom: none !important;
    }

    .item {
      cursor: pointer;
      margin-right: auto;
      border-bottom: 1px solid #707070;
      padding: 20px;
      height: 120px;
      .text {
        color: #00236f;
        font-weight: 700;
      }
    }
  }
}

// ********SERVICES SECTION*********

.services {
  background: #fff;
  .business-partners {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    overflow: hidden;
    .item {
      width: 20%;
      margin-bottom: 20px;
    }
  }
  .title {
    color: #333333;
    margin-bottom: 1rem;
  }
  .icon {
    width: 60px;
  }
  .items {
    .item:nth-child(odd) {
      border-right: 1px solid #707070;
    }

    .item:nth-last-child(1):nth-child(odd),
    .item:last-child {
      border-bottom: none !important;
    }

    .item {
      cursor: pointer;
      margin-right: auto;
      border-bottom: 1px solid #707070;
      padding: 20px;
      height: 120px;
      .text {
        color: #00236f;
        font-weight: 700;
      }
    }
  }
}

.sales {
  height: 325px;
  position: relative;
  .title {
    color: #333333;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .text {
    position: absolute;
    top: 45%;
    left: 16px;
    transform: translate(-16px, -55%);
    padding-left: 10%;
    padding-right: 50%;
  }
  .title {
    font-weight: bold;
    color: #627a86;
    text-decoration: underline;
  }
  .description {
    color: #627a86;
  }
  .buy-now {
    margin-top: 20px;
    color: #fff;
    background: #007fcf;
    border-radius: 100px;
    padding: 12px 35px;
    font-size: 14px;
  }
}

@media (max-width: 575px) {
  .choose-products {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
  .categories {
    .items {
      .item {
        height: 150px;
        .category-title {
          justify-content: center;
        }
        .text {
          font-size: 16px;
        }
      }
    }
  }
  .services {
    .items {
      .item {
        height: 150px;
        .category-title {
          justify-content: center;
        }
        .text {
          font-size: 16px;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .banner {
    h1.title {
      font-size: 64px;
    }
  }

  .categories {
    .items {
      margin: 0 auto;
      max-width: 700px;
    }
  }

  .services {
    .items {
      margin: 0 auto;
      max-width: 700px;
    }
  }
}
</style>
<template>
  <div>
    <div class="loading" v-if="$store.getters.loading">
      <div class="spinner-grow text-success"></div>
    </div>

    <div v-else>
      <div
        id="carouselExampleSlidesOnly"
        class="carousel slide"
        data-ride="carousel"
      >
        <div class="carousel-inner">
          <div
            class="carousel-item active banner"
            v-for="banner in banners"
            :key="banner.id"
          >
            <img
              class="d-block w-100"
              v-if="typeof banner.Fotoja !== 'undefined'"
              :src="banner.Fotoja"
              alt="First slide"
            />
            <div class="text-center text w-100">
              <h1 class="title">Shkel e Shko</h1>
              <h3 class="description mt-3">Professional Cleaning Soulution</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="categories pt-5 pb-5">
        <h2 class="title">KATEGORITË</h2>

        <!-- <div class="row justify-content-center items mt-5"> -->
        <div class="categoriesContainer">
          <!--            <VueSlickCarousel v-bind="slickOptions1">-->
          <div class="fullWidthCategories">
            <div class="container">
              <router-link
                @click="changeRoute(category, 'sub')"
                class="carousel-content"
                :to="{
                  name: 'CategoryProductsWeb',
                  params: { category: category.slug, page: 1 },
                }"
                v-for="category in $store.getters.categories"
                :key="category.ID"
              >
                <div class="item">
                  <div>
                    <img class="icon" :src="category.FotojaAktive" />
                    <!-- </div>
                    <div
                      class="
                        col-sm-8
                        text-sm-left
                        category-title
                        text-center
                        d-flex
                        align-self-center
                      "
                    > -->
                    <h5 class="text carousel-content-text">
                      {{ category.name }}
                    </h5>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
          <!--            </VueSlickCarousel>-->
        </div>
      </div>
      <div class="our-mission pt-5 pb-5 bg-white">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <h1 class="title mt-5">MISIONI YNË</h1>
              <p class="first-description">
                Synimi i Shkel e Shko Sh.P.K. është instalimi i kulturës së
                higjijenës me standarde Europiane duke ofruar një gamë të gjërë
                të produkteve kualitative.
              </p>

              <div class="col-12 pl-3 ml-3 second-description">
                <p>
                  Vitet në vijim do të karakterizohen me investime të mëdhaja në
                  produkte me ISO dhe HACCP standarde dhe për këtë arsyje ne e
                  shofim këtë si mundësi dhe fokus në investimet e ardhshme. -
                  Të ofrojmë produkte për pastrim të kualitetit të lartë dhe
                  produkte kimike me efikasitet sa më të lartë. - Të zhvillojmë
                  një sistem të distribuimit sa më të shpejtë dhe efikas duke
                  llogaritur në një numer te lartë të produkteve të cilat
                  gjinden në stokun tonë. - Të lansojmë një vijë moderne dhe
                  biodegraduese të produkteve kimike.
                </p>
              </div>

              <router-link :to="{ name: 'AboutUsWeb' }" class="btn learn-more"
                >Për ne</router-link
              >
            </div>
            <div class="col-md-6">
              <img class="w-100 h-100" src="@/assets/images/office.png" />
            </div>
          </div>
        </div>
      </div>

      <div class="services pt-5 pb-5">
        <div class="text-center">
          <div class="container text-center">
            <h2 class="title">BIZNESET</h2>
            <div class="business-partners">
              <!--              <VueSlickCarousel v-bind="slickOptions" ref="businessCarousel">-->
              <div
                class="item"
                v-for="business in $store.getters.bizneset"
                :key="business.ID"
              >
                <router-link
                  class="carousel-content"
                  :to="{
                    name: 'CategoryProductsWeb',
                    params: { category: business.slug, page: 1 },
                  }"
                >
                  <div>
                    <img
                      style="width: 50px; height: 50px"
                      class="icon"
                      src="../../../assets/images/logo2.svg"
                    />
                    <!-- </div>
                    <div
                      class="
                        col-sm-8
                        text-sm-left
                        category-title
                        text-center
                        d-flex
                        align-self-center
                      "
                    > -->
                    <h5 class="text carousel-content-text">
                      {{ business.name }}
                    </h5>
                  </div>
                </router-link>
              </div>
              <!--              </VueSlickCarousel>-->
            </div>
          </div>
        </div>
      </div>
      <div class="sales">
        <picture class="d-block w-100">
          <source
            class="w-100"
            srcset="@/assets/images/1920x325.png"
            media="(min-width: 991px)"
          />
          <source
            class="w-100"
            srcset="@/assets/images/768x325.png"
            media="(max-width: 991px)"
          />
          <source
            class="w-100"
            srcset="@/assets/images/450x325.png"
            media="(max-width: 450px)"
          />
          <img class="w-100" src="@/assets/images/1920x325.png" alt="Baneri" />
        </picture>
      </div>

      <div class="services pt-5 pb-5">
        <div class="text-center">
          <div class="container text-center">
            <h2 class="title">KLIENTËT</h2>
            <div class="business-partners">
              <!--              <VueSlickCarousel v-bind="slickOptions" ref="businessCarousel">-->
              <div
                class="item"
                v-for="business in $store.getters.bizneset"
                :key="business.ID"
              >
                <router-link
                  class="carousel-content"
                  :to="{
                    name: 'CategoryProductsWeb',
                    params: { category: business.slug, page: 1 },
                  }"
                >
                  <div>
                    <img
                      style="width: 50px; height: 50px"
                      class="icon"
                      src="../../../assets/images/logo2.svg"
                    />
                    <!-- </div>
                    <div
                      class="
                        col-sm-8
                        text-sm-left
                        category-title
                        text-center
                        d-flex
                        align-self-center
                      "
                    > -->
                    <h5 class="text carousel-content-text">
                      {{ business.name }}
                    </h5>
                  </div>
                </router-link>
              </div>
              <!--              </VueSlickCarousel>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { url, urlWeb } from "../../../url";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  components: {
    VueSlickCarousel,
  },
  data() {
    return {
      categories: [],
      bizneset: [],
      banners: [],
      slickOptions: {
        speed: 4000,
        autoplay: true,
        autoplaySpeed: 0,
        cssEase: "linear",
        slidesToShow: 5,
        slidesToScroll: 1,
        infinite: true,
        swipeToSlide: true,
        centerMode: true,
        focusOnSelect: true,
        responsive: [
          {
            breakpoint: 750,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
            },
          },
        ],
      },
      slickOptions1: {
        speed: 4000,
        autoplay: true,
        autoplaySpeed: 0,
        cssEase: "linear",
        slidesToShow: 5,
        slidesToScroll: 1,
        infinite: true,
        swipeToSlide: true,
        centerMode: true,
        focusOnSelect: true,
        rows: 1,
        slidesPerRow: 1,
        responsive: [
          {
            breakpoint: 750,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
            },
          },
        ],
      },
    };
  },
  beforeCreate() {
    this.$axios
      .get(urlWeb + "firstPageRequest", null)
      .then((res) => {
        this.categories = res.data.categories;
        this.bizneset = res.data.bizneset;
        this.banners = res.data.baneri;
        // this.items = res.data.cart.length
      })
      .catch((e) => {
        console.log(e);
      });
  },
  beforeMount() {
    window.scrollTo(0, 0);
    localStorage.removeItem("shkeleShkoSubCategories");
  },
  methods: {
    nextCategory() {
      this.$refs.businessCarousel.next();
    },
    changeRoute(category, type) {
      if (type === "sub") {
        localStorage.setItem(
          "shkeleShkoSubCategories",
          JSON.stringify(category?.subcategories)
        );
      } else {
        localStorage.removeItem("shkeleShkoSubCategories");
      }
      // if (this.currentUrl == "shop") {
      //   this.$router
      //     .replace({
      //       name: "CategoryProductsShop",
      //       params: { category: category.slug, page: 1 },
      //     })
      //     .then((res) => {
      //       this.callProducts = true;
      //       this.$emit("callProducts", {
      //         call: this.callProducts,
      //         param: category.slug,
      //         subCategories: category.subcategories,
      //       });
      //       window.scrollTo(0, 0);
      //     })
      //     .catch((error) => {});
      // } else {
      //   this.$router
      //     .replace({
      //       name: "CategoryProductsWeb",
      //       params: { category: category.slug, page: 1 },
      //     })
      //     .then((res) => {
      //       window.scrollTo(0, 0);
      //     })
      //     .catch((error) => {});
      // }
      this.$router
        .replace({
          name:
            this.currentUrl === "shop"
              ? "CategoryProductsShop"
              : "CategoryProductsWeb",
          params: { category: category.slug, page: 1 },
        })
        .then((res) => {
          this.callProducts = true;
          this.$emit("callProducts", {
            call: this.callProducts,
            param: category.slug,
            subCategories: category.subcategories,
          });
          window.scrollTo(0, 0);
        })
        .catch((error) => {});
    },
  },
};
</script>
