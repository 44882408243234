var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$store.getters.loading)?_c('div',{staticClass:"loading"},[_c('div',{staticClass:"spinner-grow text-success"})]):_c('div',[_c('div',{staticClass:"\n        row\n        m-0\n        all-products\n        text-center\n        d-flex\n        text-white\n        align-content-center\n      "},[(
          typeof _vm.$store.getters.categories !== 'undefined' &&
          typeof _vm.$store.getters.bizneset !== 'undefined'
        )?_c('div',{staticClass:"col-md-12"},[(
            _vm.$store.getters.categories.find(function (x) { return x.slug === _vm.activeCategory; })
          )?_c('h3',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$store.getters.categories.find(function (x) { return x.slug === _vm.activeCategory; }) .name)+" ")]):(
            _vm.$store.getters.bizneset.find(function (x) { return x.slug === _vm.activeCategory; })
          )?_c('h3',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$store.getters.bizneset.find(function (x) { return x.slug === _vm.activeCategory; }) .name)+" ")]):_vm._e()]):_vm._e()]),_c('Categories',{attrs:{"selectedCategoryGeneratedTag":_vm.selectedCategoryGeneratedTag,"subCategories":_vm.subCategories},on:{"callProducts":_vm.callProducts}}),_c('div',{staticClass:"container pt-5 pb-4 pl-sm-0 pr-sm-0"},[_c('div',{staticClass:"category-products"},[_c('div',{staticClass:"row"},_vm._l((_vm.products),function(product){return _c('Product',{key:product.id,attrs:{"root":'shop',"colLg":"3","colMd":"4","data":product,"show":_vm.currentUrl === 'shop'
                ? !product.Categories.find(function (c) { return c.name == 'Web'; })
                : !product.Categories.find(function (c) { return c.name == 'E-Shop'; })}})}),1)]),(this.maxNumberOfPages > this.page)?_c('div',{staticClass:"text-center show-more mt-5"},[(!_vm.loadingMore)?_c('button',{staticClass:"btn greenBackground",on:{"click":function($event){return _vm.loadMoreProducts()}}},[_vm._v(" Shfaq më shumë ")]):_c('button',{staticClass:"btn greenBackground",attrs:{"type":"button","disabled":""}},[_c('span',{staticClass:"spinner-border spinner-border-sm",attrs:{"role":"status","aria-hidden":"true"}}),_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])]):_vm._e()])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }