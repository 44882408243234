<style lang="scss" scoped>
    .grey-input {
        background: #F6F6F6;
        border: 0;
        border-radius: 0;
        padding: 22px 20px;
    }
    
    .register-btn {
        background: #007FCF;
        color: #fff;
        border-radius: 0;
        font-size: 14px;
        width: 300px;
        padding: 10px;
        display: block;
        margin: 0 auto;
    }
    .grey-input-select {
        background: #F6F6F6;
        border: 1px solid #EEEEEE;
        border-radius: 0;
        height: 45.5px;
    }
</style>

<template>
    <div>
        <div class="loading" v-if="$store.getters.loading || typeof $store.getters.loading === 'undefined'">
            <div class="spinner-grow text-success"></div>
        </div>

        <div class="container" v-else>
            <div class="cart pt-5 pb-5">
                <div class="second-payment">
                    <h4 class="title text-center mb-3">Pagesa</h4>
                    
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label class="text-muted">Email Address *</label>
                            <input type="email" v-model="email" class="form-control grey-input" placeholder="email@email.com">
                        </div>
                        <div class="form-group col-md-6">
                            <label class="text-muted">Password *</label>
                            <input type="password" v-model="password" class="form-control grey-input" placeholder="*********">
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label class="text-muted">Emri *</label>
                            <input type="text" v-model="emri" class="form-control grey-input">
                        </div>
                        <div class="form-group col-md-6">
                            <label class="text-muted">Mbiemri *</label>
                            <input type="text" v-model="mbiemri" class="form-control grey-input">
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="text-muted">Adresa *</label>
                        <input type="text" v-model="adresa" class="form-control grey-input" placeholder="">
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label class="text-muted">Qyteti *</label>
                            <select v-model="qyteti" class="form-control grey-input-select">
                                <option value="" selected disabled>Zgjidh Qytetin...</option>
                                <option v-for="qyteti in qytetet" :value="qyteti" :key="qyteti">{{qyteti}}</option>
                            </select>
                        </div>
                        <div class="form-group col-md-6">
                            <label class="text-muted">Nr. i Telefonit *</label>
                            <input type="text" v-model="tel" class="form-control grey-input">
                        </div>
                    </div>
                    <div class="alert alert-danger mt-2 mb-0" v-if="error">
                        {{ error }}
                    </div>
                    <button @click="register()" class="btn register-btn mt-3">REGJISTROHU</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {url, authHeader, loginUrl} from '../../url'

export default {
    mounted(){
        this.$store.commit('changeLoading', false)
    },
    data() {
        return {
            error: '',
            email: '',
            password: '',
            emri: '',
            mbiemri: '',
            adresa: '',
            qyteti: '',
            tel: '',
            qytetet: [
                'Deçan',
                'Gjakovë',
                'Gllogoc',
                'Gjilan',
                'Dragash',
                'Istog',
                'Kaçanik',
                'Klinë',
                'Fushë Kosovë',
                'Kamenicë',
                'Mitrovicë Jugore',
                'Leposaviq',
                'Lipjan',
                'Novobërdë',
                'Obiliq',
                'Rahovec',
                'Pejë',
                'Podujevë',
                'Prishtinë',
                'Prizren',
                'Skënderaj',
                'Shtime',
                'Shtërpcë',
                'Suharekë',
                'Ferizaj',
                'Viti',
                'Vushtrri',
                'Zubin Potok',
                'Zveçan',
                'Malishevë',
                'Junik',
                'Mamushë',
                'Hani i Elezit',
                'Graçanicë',
                'Ranillug',
                'Partesh',
                'Kllokot',
            ],
        }
    },
    methods: {
        register() {
            this.error = ''

            if(this.email=='' || this.emri=='' || this.mbiemri=='' || this.adresa=='' || this.qyteti=='' || this.tel=='')
            {
                this.error = 'Ju lutem plotësoni të gjitha fushat!'
                return;
            }

            let data = {
                "email": this.email,
                "password": this.password,
                "conPassword": this.password,
                "name": this.emri+' '+this.mbiemri,
                "city": this.qyteti,
                "phone": this.tel,
                "address": this.adresa
            }
            
            this.$axios.post(url+'register', data).then(res=>{
               this.login()
            }).catch(error => {
                this.error = 'Ky email aktualisht ekziston!'
            })
        },
        login(){
             this.$axios.post(loginUrl, {'username': this.email, 'password': this.password}).then(res=>{
                this.$store.commit('changeLogged', { 
                    'logged': true, 
                    'token': res.data.token
                })
                this.$router.push('/shop').catch(e => {})
            }).catch(error => {
            })
        }
    }
};
</script>
