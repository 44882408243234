<style lang="scss" scoped>
.header {
  position: relative;
  .title {
    color: #00236f;
    font-size: 30px;
  }
  .header-title {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 120px;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(#007fcf54, #2472a236, transparent);
    z-index: 11;
  }
}
#carouselExampleSlidesOnly {
  height: 420px;
  .carousel-inner {
    height: 100%;
  }
  .carousel-item {
    height: 100%;
    img {
      height: 100%;
    }
  }
}
.text {
  color: #7d7d7d;
}
.banner {
  img {
    height: 300px;
    object-fit: cover;
    object-position: top;
  }
}

.section {
  .title {
    color: #00236f;
  }
}

// ********OUR MISSION SECTION*********
.our-mission {
  .title {
    font-weight: 300;
    color: #00236f;
  }
  .first-description {
    color: #7d7d7d;
  }
  .second-description {
    border-left: 2px solid #007fcf;
    color: #007fcf;
    font-size: 16px;
  }
}

.card {
  border: 0;
  .img-container {
    width: 50%;
    margin: 0 auto;
  }
  .card-img-top {
    border-radius: 100%;
  }
  .card-text {
    color: #414141;
  }
}
</style>

<template>
  <div>
    <div class="header text-center">
      <div class="row header-title">
        <div class="col-md-12">
          <h5 class="title">Për ne</h5>
        </div>
      </div>
    </div>

    <div
      id="carouselExampleSlidesOnly"
      class="carousel slide"
      data-ride="carousel"
    >
      <div class="carousel-inner">
        <div class="carousel-item active banner">
          <img
            class="d-block w-100"
            style="object-position: 70%"
            :src="require(`../../assets/images/banneri-per-ne.jpeg`)"
          />
        </div>
      </div>
    </div>

    <div class="container pt-0 pb-5">
      <div class="our-mission pt-5 pb-5 bg-white">
        <div class="row">
          <div class="col-md-6">
            <h1 class="title mt-5">MISIONI YNË</h1>
            <p class="first-description">
              Synimi i Shkel e Shko Sh.P.K. është instalimi i kulturës së
              higjijenës me standarde<br />
              Europiane duke ofruar një gamë të gjërë të produkteve kualitative.
            </p>

            <div class="col-12 pl-3 ml-3 second-description">
              <h1 class="title mt-5">MOTOJA JONË</h1>
              <p>
                - Vitet në vijim do të karakterizohen me investime të mëdhaja në
                produkte me ISO dhe HACCP standarde dhe për këtë arsyje ne e
                shofim këtë si mundësi dhe fokus në investimet e ardhshme.<br />
                - Të ofrojmë produkte për pastrim të kualitetit të lartë dhe
                produkte kimike me efikasitet sa më të lartë.<br />
                - Të zhvillojmë një sistem të distribuimit sa më të shpejtë dhe
                efikas duke llogaritur<br />
                në një numer te lartë të produkteve të cilat gjinden në stokun
                tonë.<br />
                - Të lansojmë një vijë moderne dhe biodegraduese të produkteve
                kimike.
              </p>
            </div>
          </div>
          <div class="col-md-6">
            <img class="w-100 h-100" src="@/assets/images/office.png" />
          </div>
        </div>
      </div>

      <div class="row section">
        <div class="col-md-12">
          <h5 class="title">KATEGORTITË E PRODUKTEVE</h5>

          <p class="descripton">
            Qoftë për pastrimin e hapësirave të punës, dezinfektimin e hapsirave
            të ndryshme<br />
            apo sipërfaqeve sanitare, higjiena ka pushuar prej kohësh të jetë
            diçka për të cilën<br />
            mendojnë vetëm individë të caktuar, por një bazë e nevojshme për një
            mënyrë<br />
            jetese dhe punë të shëndetshme dhe premtuese në të gjitha sferat.<br />
            Për këtë qëllim Shkel e Shko i ka ndarë produktet e veta në kategori
            të ndryshme. <br />
            <br />
            * Letra higjijenike nga prodhuesi Italian me renome Paperdi me
            asortiman të gjerë <br />i cili i plotëson të gjitha nevojat nga
            letra industriale me kualitet të lartë e deri te <br />letrat për
            toalet të cilat treten në ujë 100% duke i plotësuar standardet më të
            larta<br />
            ekologjike Europiane. Letrat speciale për ordinanca, sallon
            kozmetike apo sallon<br />
            ondulimi me Ecolabel sertifikata plotësojnë gamen e produkteve nga
            letra.<br />
            <br />
            * Produktet kimike për pastrim nga brendet e mirënjohura botërore si
            Ecolab, Buzil<br />
            dhe Interchem mund të përdoren në të gjitha degët industriale e pse
            jo edhe në <br />shtëpi duke u bazuar në efikasitetin e tyre me
            formula të qëndrueshme dhe fokus <br />në ruajtjen e mjedisit ku
            jetojmë.<br /><br />

            * Mirëmbajtja e objekteve nuk do të ishte e mundshme pa pajisje
            adekuate për pastrim.<br />
            Në ofertën tonë kemi vendosur prodhimet nga Vileda Professional, TWT
            tools dhe Pla<br />
            me karroca modulare, kofa të ndryshme, mopa me përformansa të larta
            nga pambuku<br />
            apo mikrofibrat, shpuza të ndryshme, lecka të koduara me ngjyra për
            pluhur dhe<br />
            xhama,lecka speciale për gastronomi, fshisa, kacia, komplete
            profesionale për<br />
            pastrimin e xhamave...<br /><br />
            * Si pjesë e pandarshme e objekteve tuaja janë edhe Aksesoarët e
            ndryshme për toaletin<br />
            tuaj. Me ndihmen e brendit Carpex dhe me një dizajn elegant toaleti
            juaj merr pamje të<br />
            bukur dhe plotëson të gjitha kërkesat e klientëve gjatë qëndrimit në
            toalet.<br />
            Dispanzerat manual dhe digjital nga plastika apo Inoxi për letër të
            duarve apo toaleti,<br />
            dispanzera për pastrim dhe dezinfektim të guacës së toaletit,
            dispanzera për aromatizues<br />
            si dhe sapuni për duar me aromë freskuese plotësojnë të gjitha
            kërkesat dhe standardet<br />
            në toaletin tuaj. <br /><br />* Makinat për pastrim janë produkte të
            domosdoshme në procesin e pastrimit. Me anë të<br />
            brendit Italian Fimap higjijena në objektet me sipërfaqe të mëdhaja
            do të jetë lehtë e<br />
            menaxhueshme. Maqina për pastrim të dyshemeve me dhe pa bateria,
            thithëse elektrike<br />
            si dhe maqina me avull të gjitha në një vend.<br /><br />
            *Në kohën moderne Aromatizimi i objekteve është pjesë e pashmangshme
            e imazhit<br />
            tuaj. Me anë të dispanzerave digjital dhe me një asortiman të gjerë
            të aromave nga<br />
            Solo Air dhe Hygiene Fresh ekipi ynë i specializuar do ta mbushë
            ambijentin juaj me aromë<br />
            të këndshme dhe në varësi nga madhësia (prej 20 deri 2000 m2) do të
            vendosë për llojin<br />
            adekuat të dispanzerit dhe aromës.<br /><br />
            *Tepihat me llogo në hyrje të objektit tuaj jo vetëm që zbukurojnë
            hyrjet por edhe mbrojnë<br />
            ambientin e brendshëm nga papastërtitë qe vijnë nga jashtë. Stepmat
            është prodhuesi<br />
            Grek i cili në njëfar mënyre është edhe fajtori kryesor që Shkel e
            Shko është lider në tregun<br />
            Kosovar në fushën e produkteve profesionale për pastrim pasi që
            ishte edhe produkti me<br />
            të cilin edhe filloj storja e sukseshme e kompanisë tonë ne vitin
            2006. Tepihat mund të<br />
            jenë të brendshëm apo të jashtëm si dhe nga Alumini.<br /><br />
            * Dekorimi i tavolinës është trend botërorë. Salvetat dekoruese me
            llogon tuaj të prodhuara<br />
            në njëren nga fabrikat më të njohura Greke Finezza vijnë me ngjyra
            dhe dimensione të<br />
            ndryshme 20x20, 24x24, 33x33 dhe 40x40 dhe përmbyllin ofertën tonë
            në mënyren më<br />
            të bukur dhe dekorative.<br /><br />
          </p>
        </div>
      </div>

      <div class="row section">
        <div class="col-md-12">
          <h5 class="title">KUSH JEMI NE</h5>
          <p class="descritipon">
            Në sektorin e higjijenës, ‘’Shkel e Shko’’ është:<br />
            - Kompani me rritjen më të shpejtë në Kosovë<br />
            - Kompani me ekipin më profesional <br />- Kompani me investim të
            madhë në ngritje profesionale të stafit<br />
            - Kompani e cila përcjellë trendet botërore në fushën e
            higjijenës<br /><br />
            Shkel e Shko është kompani e cila me shërbim të sajë ofron edhe
            marketing në njerën<br />
            nga hapsirat e pashfrytëzuara më parë ate të dyshemesë në hyrje te
            objekteve afariste<br />
            me anë të tepihave me llogo.<br /><br />
            Shkel e Shko është anëtare e Odës Ekonomike të Kosovës
          </p>
        </div>
      </div>

      <div class="row section">
        <div class="col-md-12">
          <h5 class="title">ÇKA BËJMË NE</h5>
          <p class="descritipon">
            Gjatë 16 viteve të fundit, ‘’Shkel e Shko” ka krijuar raporte të
            shnosha dhe<br />
            korrekte me një numër të madh të kompanive në Kosovë duke ju ofruar
            asortiman<br />
            të gjerë të produkteve si detergjenta, pajisje për pastrim, karroca
            të ndryshme,<br />
            Kozmetikë për hotele, Insekticide, Aromatizues për hapsira të
            ndryshme, Tharrëse<br />
            për duar, letër për duar, letër për toalet, salveta me dhe pa llogo,
            makina për pastrim...<br />
            Me produktet tona ne i furnizojmë Hotelet, Rostoranet, Spitalet dhe
            Klinikat,<br />
            Shoping qendrat, Auto Sallonat, Kompanitë e Pastrimit dhe shtëpitë
            private.<br /><br />
          </p>
        </div>
      </div>

      <div class="row section">
        <div class="col-md-12">
          <h5 class="title">EKIPI YNË</h5>
          <p class="descritipon">
            Ne mendojmë që një staf mirë I pregaditur dhe i pathyeshëm është
            avantazhi<br />
            kryesor i yni në sektorin e higjijenës profesionale.<br />
            Eksperienca dhe marketingu efikas janë përkrahja kyqe e ekipit të
            shitjes në<br />
            teren. Sot punësojmë një ekip prej 14 personave të trajnuar mirë,
            një gjë e cila<br />
            na bënë të krenohemi edhe gjatë bisedave me klientët tanë.<br />
          </p>
        </div>
      </div>
      <div class="row section">
        <div class="col-md-12">
          <h5 class="title">LOGJISTIKA DHE DISTRIBUIMI</h5>
          <p class="descritipon">
            Me anë të një orari të planifikuar me kujdes ne mbulojmë gjithë
            teritorin e Kosovës<br />
            duke i mbuluar nevojat e klientëve në tërësi me produkte kimike dhe
            mjete për<br />
            pastrim në mënyrë efikase dhe në kohë. Në të njejtën kohë shkëmbejmë
            informatat<br />
            me blerësit tanë deri tek visët më të largëta të teritorit të
            Kosovës.<br />
            E gjithë kjo arihet me anë të logjistikës e cila pëkarhet me 4 Kombi
            dhe 3 Pick-up<br />
            vetura për shpërndarje të mallit.
          </p>
        </div>
      </div>

      <div class="row section">
        <div class="col-md-12">
          <h5 class="title">PIKA E SHITJES ME PAKICË</h5>
          <p class="descritipon">
            Në Prill të vitit 2016 të shtyrë nga kërkesat e sektorit shtëpiak
            kemi vendosur të<br />
            hapim lokalin tonë të parë me pakicë “Shkel e Shko” duke i ofruar
            edhe sektorit<br />
            shtëpiak një mundësi të furnizimit me produkte profesionale për
            higjijenë.<br />
            Ky dyqan është shëndruar për një kohë të shkurtë në një pikë
            referente për produkte<br />
            të kualitetit të lartë dhe të besueshëm të llojit të vet.<br />
            Dyqani gjindet në lagjen Bregu I Diellit tek udhëkryqi afër Sallës 1
            Tetori.<br /><br />
          </p>
        </div>
      </div>

      <div class="row section">
        <div class="col-md-12">
          <h4 class="title" style="text-align: center">LOKACIONI I DYQANIT</h4>
          <br />
          <div style="display: flex">
            <img
              class="col-md-6"
              style="object-fit: contain"
              :src="require(`../../assets/images/lokali.jpg`)"
            />
            <div class="col-md-6">
              <l-map style="height: 325px" :zoom="zoom" :center="center">
                <l-tile-layer
                  :url="url"
                  :attribution="attribution"
                ></l-tile-layer>
                <l-marker :lat-lng="markerLatLng"></l-marker>
              </l-map>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import L from "leaflet";
import { LMap, LTileLayer, LMarker } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";
export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
  },
  beforeCreate() {},
  data() {
    return {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 15,
      center: [42.654151, 21.167871],
      markerLatLng: [42.654151, 21.167871],
    };
  },
  methods: {},
};
</script>
