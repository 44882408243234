<style lang="scss" scoped>
    .orders {
        .title {
            color: #007FCF;
        }
        .my-orders {
            font-size: 1.5rem;
        }
        .grey-input {
            background: #F6F6F6;
            border: 1px solid #EEEEEE;
            border-radius: 0;
            padding: 22px 20px;
        }
        .grey-input-select {
            background: #F6F6F6;
            border: 1px solid #EEEEEE;
            border-radius: 0;
            height: 45.5px;
        }
        .login-btn {
            border-radius: 0;
            color: #fff;
            background: #007FCF;
            width: 50%;
            padding: 12px 20px;
            font-size: 14px;
            font-weight: 600;
            margin: 0 auto;
            display: block;
        }
        .table {
            thead th{
                border: 0;
                color: #B9B9B9;
                font-weight: 400;
                text-align: center;
            }
            tbody {
                tr {
                    height: 120px;
                    border: 1px solid #eee;
                    td {
                        color: #365A6E;
                        font-size: 16px;
                        font-weight: 500;
                        vertical-align: middle;
                        text-align: center;
                        .btn-download {
                            background: #007FCF;
                            color: #fff;
                            padding: 15px 50px;
                            border-radius: 0;
                        }
                    }
                }
            }
        }
    }
</style>

<template>
    <div>
        <div class="loading" v-if="orders.length == 0 && ($store.getters.loading || typeof $store.getters.loading === 'undefined')">
            <div class="spinner-grow text-success"></div>
        </div>

        <div class="container" v-else>
            <div class="orders pt-5 pb-5">
                <h4 class="title text-center mb-3">Porositë e mija</h4>
                
                <table class="table">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Data e porosisë</th>
                            <th>Totali</th>
                            <th>Shkarko faturën</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if='orders.length == 0'>
                            <td colspan="4">Nuk ka asnjë porosi</td>
                        </tr>
                        <tr v-else v-for="order in orders" :key="order.ID">
                            <td>{{ order.ID }}</td>
                            <td>{{ order.Date }}</td>
                            <td>{{ (order.Value) }}€</td>
                            <td><a :href="order.FaturaLink" class="btn btn-download">FATURA</a></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import {url, authHeader} from '../../url'

export default {
    beforeCreate() {
        this.$axios.get(url+'customerOrders', {headers: {...authHeader() }}).then(res=>{
            this.$store.commit('changeLoading', false)
            this.orders = res.data
            this.$store.commit('changeLoading', false)
        }).catch(error => {
            this.$store.commit('changeLoading', false)
        })
    },
    beforeRouteUpdate(){
        this.$axios.get(url+'customerOrders', {headers: {...authHeader() }}).then(res=>{
            this.$store.commit('changeLoading', false)
            this.orders = res.data
            this.$store.commit('changeLoading', false)
        }).catch(error => {
            this.$store.commit('changeLoading', false)
        })
    },
    data() {
        return {
            orders: []
        }
    },
    methods: {
    }
};
</script>
