<style scoped lang="scss">
     input[type="search"]::-webkit-search-cancel-button {
        -webkit-appearance: none;
    }
    .header {
        .menu {
            background: #00236F!important;
            height: 55px;

            .btn-navbar {
                background: #007FCF;
                color: #fff;
                border-radius: 50px;
                font-size: 10px;
                padding-right: 15px;
                padding-left: 15px;
            }
            ul {    
                padding: 0;
                list-style: none;
                justify-content: center;
                .nav-link {
                    font-size: 14px;
                    color: #fff;
                    width: max-content;
                }
            }
        }
    }

    #client-btn {
        border-radius: 50px;
        font-size: 10px;
        padding-right: 25px;
        padding-left: 25px;
    }

    nav {
        background: #8FCF00!important;
        color: #fff;
        .fa-phone-alt {
            font-size: 20px;
        }
    }

    .notification {
        background-color: #fff;
        color: #949494;
        text-decoration: none;
        padding: 15px 17px;
        position: relative;
        display: inline-block;
        border-radius: 100%;
        .badge {
            position: absolute;
            top: -5px;
            right: 0px;
            padding: 5px 7px;
            border-radius: 50%;
            background: red;
            color: white;
        }
        .fa-shopping-bag {
            font-size: 27px;
        }
        
    }
    
    .search {
        position: relative;
        width: 75%;
        .search-input {
            border-radius: 100px;
            padding: 1.6rem 0.75rem;
            border: none;
            box-shadow: none;
        }
        .search-input::-ms-clear {
            display: none;
        }
        .fa-search {
            z-index: 999;
            position: absolute;
            color:#999999;
            right: 25px;
            font-size: 20px;
        }
        .search-results {
            z-index: 999;
            .search-img {
                width: 50px;
                height: 50px;
            }
            .text,.price {
                color: #00236F;
            }
            padding: 1.6rem 0.75rem;
            position: absolute;
            top: 50px;
            background: #fff;
            width: 100%;
            border-radius: 0px 0px 30px 30px;
        }
        .search-active-input {
            border-radius: 30px 30px 0px 0px;
        }
    }
    .navbar-dark .navbar-toggler {
        border: none;
        outline: none;
    }

    @media (min-width: 991px) {
        nav {
            height: 115px;
        }
    }

    @media (min-width: 1200px) {
        .search-input {
            min-width: 250px;
        }
    }

    @media (max-width: 450px) {
        .header {
            .menu {
                ul {    
                    justify-content: unset;
                    overflow: scroll;
                }
            }
        }

        .search {
            width: 78%!important;
        }
    }
    
    @media (max-width: 575px) {
        .menu {
            height: auto!important;
        }
    }
    @media (max-width: 991px) {
        .search {
            float: left;
            width: 85%;
            margin-right: 3%!important;
            input {
                height: 60px;
            }
        }

        .cart {
            width: 12%!important;
            .cart-text {
                display: none!important;
            }
        }
    }
</style>

<template>
    <div class="header">
        <nav class="navbar navbar-expand-sm navbar-dark menu">
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="container">
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav mr-auto">
                        <li class="nav-item">
                            <router-link class="nav-link" :to="{ 'name': 'AboutUsShop' }">Për Ne</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" :to="{ 'name': 'WebHome' }">Web</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" :to="{ 'name': 'ContactShop' }">Kontakt</router-link>
                        </li>
                    </ul>
                    <div class="form-inline my-2 my-lg-0">
                        <router-link :to="{ 'name': 'ProfileShop' }" replace class="btn btn-navbar ml-auto mr-1" v-if="$store.getters.logged">Profili</router-link>
                        <router-link @click.native="$store.commit('changeLoading', true)" :to="{ 'name': 'MyOrdersShop' }" replace class="btn btn-navbar mr-1" v-if="$store.getters.logged">Porositë e mia</router-link>
                        <a class="btn btn-navbar" v-if="$store.getters.logged" @click="logout()">CKYQU</a>
                        <router-link @click.native="$store.commit('changeLoading', true)" :to="{ 'name': 'LoginShop' }" replace class="btn btn-navbar" v-else>PJESA E KLIENTIT</router-link>
                    </div>
                </div>
            </div>
        </nav>
        <nav class="navbar navbar-expand-lg">
            <div class="container">
                <router-link to="/shop" class="navbar-brand">
                    <img src="@/assets/images/logo.svg">
                </router-link>
             
                <div class="navbar-collapse">
                    <ul class="navbar-nav mr-auto pb-3 pb-lg-0">
                        <!-- <li class="nav-item d-flex justify-content-center">
                            <a href="#" class="mr-sm-2 d-flex align-items-center">
                                <i class="fas fa-phone-alt text-white"></i>
                            </a>
                            <div class="d-inline">
                                <p class="m-0">Hotline: </p>
                                <p class="m-0">(+100) 123 456 7890</p>
                            </div>
                        </li> -->
                    </ul>
                    <form @submit.prevent="goToSearch()" class="form-inline search pb-3 pb-lg-0 mr-2">
                        <input class="form-control search-input w-100" v-click-outside="closeSearch" @click="search($event.target.value)" @input="search($event.target.value)" :class="searchActive ? 'search-active-input' : ''" type="search" placeholder="Search entire store here..." aria-label="Search">
                        <i class="fas fa-search" @click="goToSearch()"></i>
                        <div v-if="searchActive" class="search-results">
                            <div v-if="searchResults.length > 0">
                                <router-link :to="'/shop/product-details/'+product.ID" @click.native="searchActive=false" class="row mb-1 w-100" v-for="product in searchResults" :key="product.ID">
                                    <div class="col-3">
                                        <img v-if="typeof product.Fotoja !== 'undefined'" :src="product.Fotoja[0]" class="search-img">
                                    </div>
                                    <div class="col-6">
                                        <span class="text">{{ product.Product }}</span>
                                    </div>
                                    <div class="col-3">
                                        <span class="price">{{ product.RetailPrice }}&euro;</span>
                                    </div>
                                </router-link>
                            </div>
                            <div v-else>
                                <p class="text-center text-primary">Nuk u gjetë asgjë!</p>
                            </div>
                        </div>
                    </form>
                    <div class="form-inline cart">
                        <router-link :to="{ name: 'CartShop' }" href="#" class="notification mr-2">
                            <span><i class="fa fa-shopping-bag"></i></span>
                            <span class="badge" v-if="typeof this.$store.getters.cart.card !== 'undefined'">{{ this.$store.getters.cart.card.length }}</span>
                            <span class="badge" v-else>0</span>
                        </router-link>
                        <div class="cart-text d-inline text-white">
                            <p class="m-0">Shporta</p>
                            <p class="m-0" v-if="typeof this.$store.getters.cart.card !== 'undefined' && this.$store.getters.cart.card.length==1">{{ this.$store.getters.cart.card.length }} artikull</p>
                            <p class="m-0" v-else-if="typeof this.$store.getters.cart.card !== 'undefined'">{{ this.$store.getters.cart.card.length }} artikuj</p>
                            <p class="m-0" v-else>0 artikuj</p>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
import {url, urlShop, authHeader} from '../../../url';
// import { debounce } from 'vue-debounce'

export default {
    beforeCreate(){
        this.$store.commit('changeLoading', true)

        this.$axios.post(url+'authenticate', null, {headers: {...authHeader() }}).then(res => {
            this.$store.commit('fillCart', res.data.cart)
            this.$store.commit('fillUser', res.data)
        }).catch(e => {
            if(localStorage.getItem('cart') !== null)
                this.$store.commit('fillCart', JSON.parse(localStorage.getItem('cart')))
        })
    },
    data() {
        return {
            searchInput: '',
            searchActive: false,
            searchResults: [],
        }
    },
    directives: {
        "click-outside": {
            bind: function(el, binding) {
                // Define ourClickEventHandler
                const ourClickEventHandler = event => {
                if (!el.contains(event.target) && el !== event.target) {
                    // as we are attaching an click event listern to the document (below)
                    // ensure the events target is outside the element or a child of it
                    binding.value(event); // before binding it
                }
                };
                // attached the handler to the element so we can remove it later easily
                el.__vueClickEventHandler__ = ourClickEventHandler;

                // attaching ourClickEventHandler to a listener on the document here
                document.addEventListener("click", ourClickEventHandler);
            },
            unbind: function(el) {
                // Remove Event Listener
                document.removeEventListener("click", el.__vueClickEventHandler__);
            }
        }
    },
    methods: {
        logout() {
            this.$store.commit('fillCart', false)
            this.$store.commit('changeLogged', false, '')
            this.$root.$emit('resetProducts')
        },
        closeSearch() {
            this.searchActive = false
        },
        search(val) {
            if(val.length<2)
            {
                this.searchResults = []
                this.searchActive = false
            }
            else {
                this.searchInput = val

                this.$axios.post(url+'headerSearch', {'search': val, 'limit': -1}).then(res=>{
                    this.searchActive = true
                    this.searchResults = res.data.products
                }).catch(error => {
    
                })
            }
        },
        goToSearch() {
            this.searchActive = false
            let searchInput = this.searchInput
            this.searchInput = ''
            this.$router.replace({ name: 'SearchShop', params: { input: searchInput }}).catch(e =>{})
        }
    },
};
</script>
