import Vue from 'vue'
import VueRouter from 'vue-router'
import Root from '../components/layout/Root.vue'
import WebShop from '../components/layout/WebShop.vue'
import WebMain from '../components/layout/web/Main.vue'
import WebHome from '../components/pages/web/Home.vue'
import ShopHome from '../components/pages/shop/Home.vue'
import CategoryProductsWeb from '../components/pages/shop/CategoryProducts.vue'
import Login from '../components/pages/Login.vue'
import ResetPassword from '../components/pages/ResetPassword.vue';
import Cart from '../components/pages/Cart.vue'
import ShopMain from '../components/layout/shop/Main.vue'
import CategoryProductsShop from '../components/pages/shop/CategoryProducts.vue'
import ProductDetails from '../components/pages/shop/ProductDetails.vue'
import Search from '../components/pages/Search.vue'
import Contact from '../components/pages/Contact.vue'
import Profile from '../components/pages/Profile.vue'
import MyOrders from '../components/pages/MyOrders.vue'
import Register from '../components/pages/Register.vue'
import TermsReference from '../components/pages/TermsReference.vue'
import AboutUs from '../components/pages/AboutUs.vue'
import ThankYou from '../components/pages/shop/ThankYou.vue'
import PaymentProblem from '../components/pages/shop/PaymentProblem.vue'
import AllProducts from '../components/pages/AllProducts.vue'
import Gallery from '../components/pages/web/Gallery.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Root,
    name: 'Root',
    children: [
      {
        path: '/',
        component: WebShop,
        name: 'WebShop',
      },
      {
        path: '/web',
        component: WebMain,
        name: 'WebMain',
        children: [
          {
            path: '/',
            component: WebHome,
            name: 'WebHome'
          },
          {
            path: 'category-products/:category/:page?',
            component: CategoryProductsWeb,
            name: 'CategoryProductsWeb'
          },
          {
            path: 'login',
            component: Login,
            name: 'LoginWeb'
          },
          {
            path: 'resetPassword/:true',
            component: ResetPassword,
            name: 'ResetPassword'
          },
          {
            path: 'cart',
            component: Cart,
            name: 'CartWeb'
          },
          {
            path: 'contact',
            component: Contact,
            name: 'ContactWeb'
          },
          {
            path: 'profile',
            component: Profile,
            name: 'ProfileWeb'
          },
          {
            path: 'my-orders',
            component: MyOrders,
            name: 'MyOrdersWeb'
          },
          {
            path: 'register',
            component: Register,
            name: 'RegisterWeb'
          },
          {
            path: 'terms-reference',
            component: TermsReference,
            name: 'TermsReferenceWeb'
          },
          {
            path: 'gallery',
            component: Gallery,
            name: 'GalleryWeb'
          },
          {
            path: 'about-us',
            component: AboutUs,
            name: 'AboutUsWeb'
          },
          {
            path: 'search/:input',
            component: Search,
            name: 'SearchWeb'
          },
          {
            path: 'all-products',
            component: AllProducts,
            name: 'AllProducts'
          },
          {
            path: 'product-details/:slug',
            component: ProductDetails,
            name: 'ProductDetailsweb'
          },
        ],
        beforeEnter: (to, from, next) => {
          window.scrollTo(0, 0)
          next()
        }
        // beforeEnter: (to, from, next) => {
        //     guard(to, from, next);
        // }
      },
      {
        path: '/shop',
        component: ShopMain,
        name: 'ShopMain',
        children: [
          {
            path: '/',
            component: ShopHome,
            name: 'ShopHome'
          },
          {
            path: 'category-products/:category/:page?/:subCategory?',
            component: CategoryProductsShop,
            name: 'CategoryProductsShop'
          },
          {
            path: 'product-details/:slug',
            component: ProductDetails,
            name: 'ProductDetailsshop'
          },
          {
            path: 'cart',
            component: Cart,
            name: 'CartShop'
          },
          {
            path: 'search/:input',
            component: Search,
            name: 'SearchShop'
          },
          {
            path: 'contact',
            component: Contact,
            name: 'ContactShop'
          },
          {
            path: 'login',
            component: Login,
            name: 'LoginShop'
          },
          {
            path: 'profile',
            component: Profile,
            name: 'ProfileShop'
          },
          {
            path: 'my-orders',
            component: MyOrders,
            name: 'MyOrdersShop'
          },
          {
            path: 'register',
            component: Register,
            name: 'RegisterShop'
          },
          {
            path: 'terms-reference',
            component: TermsReference,
            name: 'TermsReferenceShop'
          },
          {
            path: 'about-us',
            component: AboutUs,
            name: 'AboutUsShop'
          },
          {
            path: 'thank-you/:id',
            component: ThankYou,
            name: 'ThankYouShop'
          },
          {
            path: 'payment-problem/:id',
            component: PaymentProblem,
            name: 'PaymentProblemShop'
          }
        ],
        beforeEnter: (to, from, next) => {
          window.scrollTo(0, 0)
          next()
        }
      },
      { path: "*", redirect: "/" }
    ]
  },
]

// function guard(to, from, next) {
//     // check for valid auth token
//     axios.get(url+'isValid', {headers: {...authHeader() }}).then(res=>{
//         next()
//     }).catch(error => {
//         next({ name: 'Login' })
//     })
// };

// function isLoggedIn(to, from, next) {
//     // check for valid auth token
//     axios.get(url+'isValid', {headers: {...authHeader() }}).then(res=>{
//         if(!window.location.href.includes('dashboard'))
//             window.location.href = 'dashboard'
//     }).catch(error => {
//         next()
//     })
// };

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
