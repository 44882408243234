<style lang="scss" scoped>
.footer-main {
  position: absolute;
  bottom: -32px;
  width: 100%;
  height: 0;
  .first-footer {
    background: #00236f;
    color: #fff;
    .fa-envelope {
      font-size: 36px;
      color: #b3b3b3;
    }
    .subscribe {
      .title {
        font-weight: bold;
      }
    }
    .subscribe-email {
      input {
        border-radius: 50px 0px 0px 50px;
      }
      .send-btn {
        background: red;
        color: #fff;
        border-radius: 0px 50px 50px 0px;
      }
    }
  }

  .second-footer {
    background: #007fcf;
    color: #fff;
    font-weight: 300;
    font-size: 14px;
    .payments {
      display: flex;
      justify-content: center;
      img {
        width: 309px;
      }
    }
  }

  .sales {
    height: 325px;
    position: relative;
    .title {
      color: #333333;
      margin-top: 40px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .container {
      position: relative;
      .text {
        position: absolute;
        /* top: 45%; */
        left: 16px;
        transform: translate(-16px, -120%);
      }
    }
    .title {
      font-weight: bold;
      color: #627a86;
      text-decoration: underline;
    }
    .description {
      color: #627a86;
    }
    .buy-now {
      margin-top: 20px;
      color: #fff;
      background: #007fcf;
      border-radius: 100px;
      padding: 12px 35px;
      font-size: 14px;
    }
  }

  // ********OFFERS*******

  .offers {
    background: linear-gradient(
      to right,
      #fff 0%,
      #fff 50%,
      #00236f 50%,
      #00236f 100%
    );
    #distribution {
      .title {
        font-weight: 400;
      }
      .description {
        font-size: 20px;
        line-height: 2;
      }
    }
    .btn-kerko-oferte {
      border-radius: 100px;
      background: red;
      color: #fff;
      padding: 12px 35px;
      font-size: 14px;
      margin-top: 20px;
    }
    .choose-products {
      padding-left: 10%;
      color: #fff;
      .title {
        font-weight: 400;
      }
      .email {
        margin-top: 30px;
        background: none;
        border-radius: 0;
        padding: 20px 5% 20px 5%;
      }
    }
  }
}
@media (max-width: 768px) {
  .offers {
    #distribution {
      padding: 0;
      padding-top: 20px !important;
      .title {
        font-size: 26px;
      }
      .description {
        font-size: 16px !important;
      }
    }
    .row {
      margin: 0;
    }
    .choose-products {
      padding-top: 0 !important;
      padding-left: 0 !important;
      .title {
        font-size: 24px;
      }
    }
  }
  .second-col {
    text-align: center !important;
  }

  .subscribe-email {
    padding-right: 15px;
  }
}

@media (max-width: 991px) {
  .offers {
    background: linear-gradient(
      to bottom,
      #fff 0%,
      #fff 50%,
      #00236f 50%,
      #00236f 100%
    ) !important;
    .choose-products {
      padding: 0;
      text-align: center;
    }
  }
}
@media (max-width: 575px) {
  .sales {
    .title {
      font-size: 1.5rem;
    }
    .description {
      font-size: 0.8rem;
    }
    .container {
      .text {
        left: 30px !important;
      }
    }
  }
}
</style>

<template>
  <div class="footer-main">
<!--    <div-->
<!--      class="sales"-->
<!--      v-if="-->
<!--        $route.name != 'ResetPassword' &&-->
<!--        currentUrl == 'web' &&-->
<!--        $route.name != 'WebHome'-->
<!--      "-->
<!--    >-->
<!--      <picture class="d-block w-100">-->
<!--        <source-->
<!--          class="w-100"-->
<!--          srcset="@/assets/images/1920x325.png"-->
<!--          media="(min-width: 991px)"-->
<!--        />-->
<!--        <source-->
<!--          class="w-100"-->
<!--          srcset="@/assets/images/768x325.png"-->
<!--          media="(max-width: 991px)"-->
<!--        />-->
<!--        <source-->
<!--          class="w-100"-->
<!--          srcset="@/assets/images/450x325.png"-->
<!--          media="(max-width: 450px)"-->
<!--        />-->
<!--        <img class="w-100" src="@/assets/images/1920x325.png" alt="Baneri" />-->
<!--      </picture>-->
<!--      &lt;!&ndash; <img :src="footerData.Fotoja"> &ndash;&gt;-->
<!--      &lt;!&ndash; <div class="container">-->
<!--            <div class="text-center text w-50">-->
<!--                <h1 class="title">{{ footerData.Product }}</h1>-->
<!--                <h6 class="description mt-3">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo</h6>-->
<!--                <a class="btn buy-now">BLEJ TANI</a>-->
<!--            </div>-->
<!--        </div> &ndash;&gt;-->
<!--    </div>-->

<!--    <div class="offers" v-if="currentUrl == 'web'">-->
<!--      <div class="container">-->
<!--        <div class="row">-->
<!--          <div class="col-lg-6 pt-5 pb-5" id="distribution">-->
<!--            <h2 class="title">DISTRIBUTION AND <br />LOGISTICS</h2>-->
<!--            <p class="mt-4 description">-->
<!--              Through a carefully planned schedule, we cover all Kosovo’s-->
<!--              territory and deliver cleaning and chemistry goods efficiently and-->
<!--              in time. At the same time, this way we exchange information with-->
<!--              our clients, even in barely reachable areas. Our logistic team-->
<!--              offers services with 3 vans and 3 Pick-upes for distribution–and 1-->
<!--              Puck-up for service.-->
<!--            </p>-->
<!--          </div>-->
<!--          <div class="col-lg-6 pt-5 pb-5 choose-products">-->
<!--            <h2 class="mt-5 title">Zgjidh produktet dhe kerko oferte!</h2>-->

<!--            <input-->
<!--              type="email"-->
<!--              v-model="email"-->
<!--              class="form-control email text-white"-->
<!--              placeholder="Shkruaj emailin tuaj"-->
<!--            />-->
<!--            <p class="text-muted">Please enter amounts over 500 manually</p>-->

<!--            <div class="alert alert-danger mt-2 mb-0" v-if="error">-->
<!--              {{ error }}-->
<!--            </div>-->
<!--            <button @click="requestOffer()" class="btn btn-kerko-oferte">-->
<!--              KERKO OFERTEN-->
<!--            </button>-->
<!--            <Toasts-->
<!--              :show-progress="true"-->
<!--              :rtl="false"-->
<!--              :max-messages="5"-->
<!--              :time-out="3000"-->
<!--              :closeable="true"-->
<!--            ></Toasts>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

    <div class="first-footer">
      <div class="container pt-4">
        <div class="row p-2">
          <div class="col-md-6 first-col">
            <div class="logo">
              <img src="@/assets/images/logo.svg" />
            </div>

<!--            <p class="mt-4">-->
<!--              Shkel e Shko Sh.P.K mission is to develop cleaning culture in-->
<!--              Kosova up to European standarts and to offer widest possible range-->
<!--              of quality products needed in Kosova cleaning field.-->
<!--            </p>-->
          </div>

          <div class="col-md-6 mb-5 mb-sm-0 second-col">
            <div class="row mb-3">
              <div class="col-md-2">
                <i class="far fa-envelope"></i>
              </div>
              <div class="col-md-8 subscribe">
                <h5 class="title">Subscibe our Newsletter</h5>
                <h6>Get 25% Off first purchase!</h6>
              </div>
            </div>
            <form class="d-flex subscribe-email w-100">
              <input
                class="form-control search-input w-100"
                type="search"
                placeholder="Your email address here..."
                aria-label="Search"
              />
              <button class="btn send-btn" type="submit">Dergo</button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="second-footer">
      <div class="container pt-2 pb-2">
        <div class="row justify-content-between">
          <div class="col-md-4 pt-2 pt-md-1">
            <p
              class="
                m-md-0
                d-md-flex
                align-items-center
                text-center text-md-left
              "
            >
              Copyright C 2020 Shkel e Shko. All rights reserved
            </p>
          </div>
          <div class="col-md-3 pt-2 pt-md-1 text-center">
            <router-link
              class="mr-4 text-white text-center"
              :to="{ name: 'TermsReferenceShop' }"
              >Terms of Reference & Privacy</router-link
            >
          </div>
          <div class="col-md-5 pt-2 pt-md-0">
            <div class="payments">
              <img class="payments" src="@/assets/images/payments.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { url, authHeader } from "../../url";

export default {
  beforeCreate() {
    this.$axios
      .get(url + "webFooter")
      .then((res) => {
        this.footerData = res.data[0];
        // console.log(res.data)
      })
      .catch((e) => {});
  },
  mounted() {
    console.log(this.$route.name);
  },
  data() {
    return {
      currentUrl: this.$route.name.toLowerCase().includes("shop")
        ? "shop"
        : "web",
      footerData: [],
      email: "",
      error: "",
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      showToast: false,
    };
  },
  methods: {
    requestOffer() {
      this.error = "";

      if (this.email == null || this.email == "") {
        this.error = "Ju lutem shkruani email-in!";
        return;
      } else if (!this.reg.test(this.email)) {
        this.error = "Ju lutem shkruani një email valid!";
        return;
      }

      if (this.$store.getters.logged) {
        this.$axios
          .post(
            url + "requestOffer",
            { email: this.email },
            { headers: { ...authHeader() } }
          )
          .then((res) => {
            this.$toast.success("Oferta juaj u dergua me sukses");
          })
          .catch((e) => {
            this.error = "Duhet të keni produkte në shportë!";
          });
      } else {
        this.$axios
          .post(url + "requestOffer", {
            email: this.email,
            productsFromCart: this.$store.getters.cart.card,
          })
          .then((res) => {
            this.$toast.success("Oferta juaj u dergua me sukses");
          })
          .catch((e) => {
            this.error = "Duhet të keni produkte në shportë!";
          });
      }
    },
  },
};
</script>
