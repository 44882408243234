<style lang="scss" scoped>
.main-content {
  padding-bottom: 2.5rem;
  min-height: calc(100vh - 491px);
}
</style>
<template>
  <div class="main-content">
    <!-- Outlet for children routes -->
    <!-- <keep-alive exclude="['post', 'category', 'search', 'tag']" :max="3"> -->
    <!-- <keep-alive :max="2"> -->
    <router-view></router-view>
    <!-- </keep-alive> -->
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>
