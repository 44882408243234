<style lang="scss" scoped>
form {
  margin: 0 auto;
  width: 50%;
  height: 100%;
  input {
    background: #f6f6f6;
    border-radius: 0;
    padding: 25px 20px;
  }
  button {
    padding: 15px;
    border-radius: 0;
  }
}
</style>

<template>
  <div class="container">
    <form class="pt-5 pb-5" @submit="login">
      <div class="form-group">
        <label class="text-muted"
          >Jepni emailin tuaj
          <span class="text-danger font-weight-bold">*</span></label
        >
        <input
          v-model="email"
          class="form-control"
          aria-describedby="emailHelp"
          placeholder="Perdoruesi"
        />
      </div>
      <!-- <div class="mb-4">
        <label class="form-check-label text-muted"
          >Keni harruar fjalekalimin?</label
        >
      </div> -->

      <div class="alert alert-danger" role="alert" v-if="error">
        {{ error }}
      </div>

      <button class="btn btn-primary w-100">Gjenero Password</button>

      <!-- <p class="pt-3">Nuk keni llogari ? <router-link to="/web/register">Regjistrohu</router-link> tani</p> -->
    </form>
  </div>
</template>

<script>
import { loginUrl, url, authHeader } from "../../url";

export default {
  data() {
    return {
      email: "",
      error: "",
    };
  },
  components: {},
  methods: {
    login(e) {
      e.preventDefault();

      if (this.username == "" || this.password == "") {
        this.error = "Plotësoni të gjitha fushat";
        return;
      }

      var data = {
        username: this.username,
        password: this.password,
      };

      this.$axios
        .post(loginUrl, data)
        .then((res) => {
          var token = res.data.token;
          this.$store.commit("changeLogged", {
            logged: true,
            token: token,
          });
          this.error = "";

          var currentUrl = this.$route.name.toLowerCase().includes("shop")
            ? "/shop"
            : "/web";
          location.href = currentUrl;
        })
        .catch((error) => {
          console.log(error);
          this.error = "Përdoruesi ose Fjalëkalimi janë gabim";
        });
    },
  },
};
</script>
