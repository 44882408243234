<style lang="scss" scoped>
.card {
  border-radius: 0;
  font-size: 14px;
  padding: 10px 10px;
  height: 440px;
  .image-container {
    position: relative;
    .bottom-left {
      left: 0;
      position: absolute;
      top: 0;
      .btn-new {
        color: #fff;
        background: #7a9b08;
      }
      .btn-discount {
        color: #fff;
        background: #f12a43;
      }
    }
    img {
      object-fit: contain;
      height: 270px;
    }
  }
  .card-title {
    color: #aaaaaa;
  }
  .card-text {
    color: #00236f;
    font-weight: 600;
  }
  .card-buttons {
    .price {
      font-weight: 600;
      font-size: 14px;
    }
    .discount-price {
      font-size: 0.8rem;
    }
    .quantity-row {
      padding-right: 5px;
      .quantity {
        position: relative;
        input {
          border: none;
          border-radius: 100px;
          height: 35px;
          color: #365a6e;
          font-weight: 700;
          text-align: center;
        }
        .input-shop {
          background: #8fcf0033;
        }
        .input-web {
          background: #007fcf34;
        }
        .minus-btn {
          cursor: pointer;
          text-decoration: none;
          position: absolute;
          left: 5px;
          color: #95bace;
          font-size: 20px;
          top: 1px;
          font-weight: 700;
        }
        .plus-btn {
          cursor: pointer;
          text-decoration: none;
          position: absolute;
          right: 5px;
          color: #95bace;
          font-size: 20px;
          top: 1px;
          font-weight: 700;
        }
      }
    }
    .cart {
      padding: 0 !important;
      .add-to-cart {
        float: right;
        cursor: pointer;
      }
      .round {
        width: 30px;
        pointer-events: none;
        background: #eeeeee;
        color: #888888;
        padding: 18px;
        border-radius: 100%;
        position: relative;
        .cart-img {
          height: 23px;
          width: 20px;
          position: absolute;
          top: 5px;
          left: 7px;
          background-image: url("../../assets/images/cart.svg");
        }
      }
    }
  }
}

.card-web:hover {
  border: 2px solid #007fcf;
  padding: 9px 9px;
}

.card-shop:hover {
  border: 2px solid #8fcf00;
  padding: 9px 9px;
}

.card .card-buttons .cart .round:hover {
  background: #cf0000;
  color: #fff;
  .cart-img {
    background: url("../../assets/images/cartWhite.svg");
  }
}
.card .card-buttons .cart .round.active {
  background: #cf0000;
  color: #fff;
  .cart-img {
    background: url("../../assets/images/cartWhite.svg");
  }
}
</style>

<template>
  <div
    class="mb-2 col-6"
    v-if="show"
    :class="['col-lg-' + colLg, 'col-md-' + colMd]"
  >
    <div class="card" :class="root == 'web' ? 'card-web' : 'card-shop'">
      <router-link
        exact
        :to="{ name: `ProductDetails${root}`, params: { slug: data.ID } }"
      >
        <div class="image-container">
          <img
            class="card-img-top"
            v-if="typeof data.Fotoja !== 'undefined'"
            :src="data.Fotoja[0]"
          />
          <div class="bottom-left">
            <button class="btn btn-sm btn-new mr-1" v-if="data.new_product">
              NEW
            </button>
            <button class="btn btn-sm btn-discount" v-if="data.discount > 0">
              -{{ data.discount }}%
            </button>
          </div>
        </div>
      </router-link>
      <div class="card-body p-0 d-flex justify-content-between flex-column">
        <div class="card-body-text">
          <p class="card-title m-0">
            <span v-for="(category, index) in data.Categories" :key="index">
              {{ index !== 0 ? "," : "" }} {{ category.name }}
            </span>
          </p>
          <p class="card-text">{{ data.Product }}</p>
        </div>
        <div class="card-buttons">
          <div class="row m-0" v-if="isInShop">
            <div
              class="col-12 col-md-4 d-flex align-items-center p-0 pb-1"
              v-if="isInShop[1] == 'shop'"
            >
              <span v-if="typeof data.discount === 'undefined'" class="price"
                >{{ data.RetailPrice }}&euro;</span
              >
              <div v-else>
                <h6 class="discount-price text-danger m-0 font-weight-bold">
                  {{ data.RetailPrice }}&euro;
                </h6>
                <h6 class="price text-muted">
                  <s
                    ><small>{{ data.RegularPrice }}&euro;</small></s
                  >
                </h6>
              </div>
            </div>

            <div
              class="col-9 col-sm-5 col-sm-5 pl-0 quantity-row"
              :class="root == 'shop' ? 'col-5' : 'col-9'"
            >
              <div class="quantity">
                <a
                  @click="quantity > 1 ? changeQuantity('minus') : ''"
                  class="minus-btn"
                  >−</a
                >
                <input
                  disabled
                  class="form-input w-100"
                  :class="root == 'web' ? 'input-web' : 'input-shop'"
                  v-model="quantity"
                />
                <a @click="changeQuantity('plus')" class="plus-btn">+</a>
              </div>
            </div>

            <div class="col-3 text-right cart">
              <a class="add-to-cart" @click="addToCart()">
                <div class="round" :class="isInCart != null ? 'active' : ''">
                  <div class="cart-img"></div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { url, authHeader } from "../../url";
import store from "../../store/index";

export default {
  data() {
    return {
      isInCart:
        typeof this.data.isInCart !== "undefined" && this.data.isInCart
          ? true
          : null,
      quantity: 1,
      isInShop: this.$route.fullPath.split("/"),
    };
  },
  props: ["data", "root", "colMd", "colLg", "show"],
  mounted() {
    this.$root.$on("resetProducts", () => {
      this.isInCart = false;
      this.quantity = 1;
    });
    if (!this.$store.getters.logged) {
      if (typeof this.$store.getters.cart.card !== "undefined") {
        var cartData = this.$store.getters.cart.card;

        for (var i = 0; i < cartData.length; i++) {
          if (cartData[i].ID === this.data.ID) {
            this.isInCart = i;
            this.quantity = cartData[i].quantity;
          }
        }
      }
    }
  },
  methods: {
    changeQuantity(action) {
      if (action == "minus") this.quantity = this.quantity - 1;
      else this.quantity = this.quantity + 1;

      if (this.$store.getters.logged && this.isInCart != null) {
        let data = {
          quantity: this.quantity,
          variation_id: 0,
          product_id: this.data.ID,
        };

        this.$axios
          .post(url + "addToCart", data, { headers: { ...authHeader() } })
          .then((res) => {
            this.isInCart = true;
            this.$store.commit("fillCart", res.data);
          })
          .catch((e) => {
            console.log(e);
          });
      } else if (this.isInCart != null) {
        var cartData = this.$store.getters.cart;

        if (cartData == false) {
          this.data.quantity = this.quantity;

          cartData = {
            shipping: 0,
            card: [this.data],
            total: this.data.RetailPrice,
          };
        } else if (this.isInCart != null) {
          var sumToBeAdded =
            (this.quantity - cartData.card[this.isInCart].quantity) *
            parseFloat(this.data.RetailPrice).toFixed(2);
          cartData.card[this.isInCart].quantity = this.quantity;
          sumToBeAdded = parseFloat(cartData.total) + parseFloat(sumToBeAdded);
          cartData.total = sumToBeAdded.toFixed(2);
        } else {
          cartData.card.push(this.data);
          var sumToBeAdded =
            parseFloat(cartData.total) +
            parseFloat(this.quantity * this.data.RetailPrice);
          cartData.total = sumToBeAdded.toFixed(2);
        }

        this.$store.commit("fillCart", cartData);
        localStorage.setItem("cart", JSON.stringify(cartData));
      }
    },
    addToCart() {
      if (this.$store.getters.logged) {
        let data = {
          quantity: this.quantity,
          variation_id: 0,
          product_id: this.data.ID,
        };

        if (this.isInCart != null) {
          this.$axios
            .post(url + "removeFromCart", data, {
              headers: { ...authHeader() },
            })
            .then((res) => {
              this.$store.commit("fillCart", res.data);
              this.isInCart = null;
              this.quantity = 1;
            })
            .catch((error) => {});
          return;
        }

        this.$axios
          .post(url + "addToCart", data, { headers: { ...authHeader() } })
          .then((res) => {
            this.isInCart = true;
            this.$store.commit("fillCart", res.data);
          })
          .catch((e) => {});
      } else if (this.isInCart != null) {
        this.isInCart = null;
        var cartData = this.$store.getters.cart;
        var removeKey = -1;

        for (const [key, value] of Object.entries(cartData.card)) {
          if (value.ID == this.data.ID) removeKey = key;
        }

        cartData.card.splice(removeKey, 1);
        var sumToBeAdded =
          parseFloat(cartData.total) -
          parseFloat(this.quantity * this.data.RetailPrice);
        cartData.total = sumToBeAdded.toFixed(2);
        this.$store.commit("fillCart", cartData);
        localStorage.setItem("cart", JSON.stringify(cartData));
        this.quantity = 1;
      } else {
        var cartData = this.$store.getters.cart;
        if (cartData == false) {
          this.data.quantity = this.quantity;

          cartData = {
            shipping: 0,
            card: [this.data],
            total: this.data.RetailPrice,
          };
        } else {
          cartData = {
            shipping: 0,
            card: [],
            total: 0,
          };
          this.data.quantity = this.quantity;
          cartData.card.push(this.data);
          var sumToBeAdded =
            parseFloat(cartData.total) +
            parseFloat(this.quantity * this.data.RetailPrice);
          cartData.total = parseInt(sumToBeAdded.toFixed(2));
        }

        this.isInCart = cartData.card.length - 1;

        this.$store.commit("fillCart", cartData);
        localStorage.setItem("cart", JSON.stringify(cartData));
      }
    },
  },
};
</script>
