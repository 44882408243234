<style lang="scss" scoped>
.cart {
  .no-products {
    height: 50vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .title {
    color: #007fcf;
  }
  .product {
    border: 1px solid #eeeeee;
    padding: 10px 20px;
    text-align: center;
    .product-title {
      color: #365a6e;
      font-weight: 600;
    }
    .product-img {
      max-width: 100px;
      width: 100%;
      height: 100px;
      object-fit: contain;
    }
    .center {
      display: flex;
      align-items: center;
    }
    .remove-product {
      color: #ff2742cb;
      cursor: pointer;
    }
    .quantity {
      position: relative;
      input {
        border: none;
        border-radius: 100px;
        padding: 5px;
        color: #365a6e;
        font-weight: 700;
        text-align: center;
      }
      .input-shop {
        background: #8fcf0033;
      }
      .input-web {
        background: #007fcf34;
      }
      .minus-btn {
        cursor: pointer;
        text-decoration: none;
        position: absolute;
        left: 5px;
        color: #95bace;
        font-size: 20px;
      }
      .plus-btn {
        cursor: pointer;
        text-decoration: none;
        position: absolute;
        right: 5px;
        color: #95bace;
        font-size: 20px;
      }
    }
  }
  .kerko-oferten {
    background: #f6fafb;
    border: 1px solid #eeeeee;
    .title {
      text-align: center;
      color: #365a6e;
    }
    textarea {
      background: transparent;
      border: none;
      color: rgb(98, 122, 134);
      width: 100%;
      resize: vertical;
      overflow: hidden;
    }
  }
  .payment {
    background: #f6fafb;
    border: 1px solid #eeeeee;
    .title {
      text-align: center;
      color: #365a6e;
    }
    .total {
      color: #627a86;
    }
    .payments {
      color: #365a6e;
      font-weight: 600;
      font-size: 15px;
    }
  }
}

.nextPage {
  border-radius: 0;
  color: #fff;
  background: #007fcf;
  width: 100%;
  padding: 12px 20px;
  font-size: 14px;
  font-weight: 600;
  margin-top: 25px;
}

.second-payment {
  .nextPage {
    display: block;
    width: 50%;
    // margin: 0 auto;
  }
  .prevPage {
    background: #b7b7b7;
    width: 50%;
    display: block;
  }
  .title {
    color: #007fcf;
  }
  button {
    border-radius: 0;
    color: #fff;
    background: #007fcf;
    width: 100%;
    padding: 12px 20px;
    font-size: 14px;
    font-weight: 600;
    margin-top: 25px;
  }
  input {
    background: #f6f6f6;
    border: none;
    padding: 22px 15px;
  }
  select {
    background: #f6f6f6;
    border: none;
    height: 45px;
  }
}

.no-products {
  height: 53vh;
  .text {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .cart {
    .product {
      .center {
        justify-content: center;
        flex-direction: column;
      }
      .quantity-row {
        float: left;
        width: 80%;
      }
      .remove-row {
        float: right;
        width: 5%;
      }
    }
  }
}
</style>

<template>
  <div>
    <div class="alert alert-success mt-5" v-if="paymentDone">
      Pagesa u krye me sukses!
    </div>

    <div
      class="loading"
      v-else-if="
        $store.getters.loading || typeof $store.getters.loading === 'undefined'
      "
    >
      <div class="spinner-grow text-success"></div>
    </div>

    <div class="container" v-else>
      <div
        class="cart pt-5 pb-5"
        v-if="
          typeof $store.getters.cart.card !== 'undefined' &&
          $store.getters.cart.card.length > 0
        "
      >
        <div class="first-payment" v-if="currentPage == 1">
          <h4 class="title text-center mb-3">Shporta</h4>

          <div class="row">
            <div class="col-md-8">
              <div
                class="products mb-2"
                v-for="product in $store.getters.cart.card"
                :key="product.ID"
              >
                <CartProduct :data="product" />
              </div>
            </div>

            <div class="col-md-4 mt-md-0 mt-5" v-if="currentUrl == 'web'">
              <div class="kerko-oferten p-3 text-center">
                <h6 class="title">Kerko oferten</h6>
                <hr />
                <input
                  type="text"
                  v-model="offerEmail"
                  placeholder="email@email.com"
                  class="form-control mb-3"
                />
                <input
                  type="text"
                  v-model="emri"
                  placeholder="Name"
                  class="form-control mb-3"
                />
                <input
                  type="text"
                  v-model="emriKompanise"
                  placeholder="Company details"
                  class="form-control mb-3"
                />
                <hr />
                <button class="btn btn-primary" @click="requestOffer()">
                  KERKO OFERTEN
                </button>
              </div>
            </div>

            <div class="col-md-4 mt-md-0 mt-5" v-else>
              <div class="payment p-3">
                <h5 class="title">Total</h5>
                <hr />
                <div class="total d-flex justify-content-between">
                  <h2 class="text">Totali</h2>
                  <h2
                    class="price"
                    v-if="typeof $store.getters.cart.total !== 'undefined'"
                  >
                    {{ `${$store.getters.cart.total}€` }}
                  </h2>
                </div>
                <hr />
                <div class="payments">
                  <div class="form-check">
                    <input
                      type="radio"
                      v-model="payment"
                      value="procredit"
                      id="procredit"
                      class="form-check-input"
                    />
                    <label for="procredit" class="form-check-label"
                      >Pagesa me bank</label
                    >
                  </div>
                  <div class="form-check">
                    <input
                      type="radio"
                      v-model="payment"
                      id="cod"
                      value="cod"
                      class="form-check-input"
                    />
                    <label for="cod" class="form-check-label"
                      >Pagesa me Cash në dorë</label
                    >
                  </div>
                  <div class="form-check">
                    <input
                      type="radio"
                      v-model="payment"
                      value="bacs"
                      id="bacs"
                      class="form-check-input"
                    />
                    <label for="bacs" class="form-check-label"
                      >Transfer bankar</label
                    >
                  </div>
                </div>
                <div class="alert alert-danger mt-2 mb-0" v-if="paymentError">
                  {{ paymentError }}
                </div>
                <button
                  class="btn nextPage"
                  @click="
                    payment != false
                      ? nextPage(2)
                      : (paymentError = 'Ju lutem zgedhni një lloj të pagesës')
                  "
                >
                  VAZHDO TE PAGESA
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="pt-5 pb-5" v-if="currentPage == 2">
          <div class="second-payment">
            <h4 class="title text-center mb-3">Pagesa</h4>
            <div class="form-group">
              <label class="text-muted">Email Address *</label>
              <input
                type="email"
                v-model="email"
                class="form-control"
                placeholder="email@email.com"
              />
            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label class="text-muted">Emri *</label>
                <input type="text" v-model="emri" class="form-control" />
              </div>
              <div class="form-group col-md-6">
                <label class="text-muted">Mbiemri *</label>
                <input type="text" v-model="mbiemri" class="form-control" />
              </div>
            </div>
            <div class="form-group">
              <label class="text-muted">Adresa *</label>
              <input
                type="text"
                v-model="adresa"
                class="form-control"
                placeholder=""
              />
            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label class="text-muted">Qyteti *</label>
                <select v-model="qyteti" class="form-control">
                  <option value="" selected disabled>Zgjidh Qytetin...</option>
                  <option
                    v-for="qyteti in qytetet"
                    :value="qyteti"
                    :key="qyteti"
                  >
                    {{ qyteti }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-6">
                <label class="text-muted">Nr. i Telefonit *</label>
                <input type="text" v-model="tel" class="form-control" />
              </div>
            </div>
            <div class="alert alert-danger mt-2 mb-0" v-if="paymentError">
              {{ paymentError }}
            </div>
            <div class="d-flex flex-row">
              <div class="p-0 col-md-6 mt-3">
                <button class="btn prevPage" @click="currentPage--">
                  Kthehu Prapa
                </button>
              </div>
              <div class="p-0 col-md-6 mt-3 d-flex justify-content-end">
                <button
                  class="btn nextPage"
                  @click="checkSecondPayment() ? sendForm() : ''"
                >
                  VAZHDO TE PAGESA
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-else class="no-products cart">
        <h4 class="title text-center mt-3">Shporta</h4>
        <div class="text h-100">
          <h6 class="text-center">Nuk ka produkte në shportë</h6>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { url, authHeader } from "../../url";
import CartProduct from "../layout/CartProduct";

export default {
  mounted() {
    this.$store.commit("changeLoading", false);
    if (this.$store.getters.logged) {
      this.$axios
        .get(url + "getCostumerData", { headers: { ...authHeader() } })
        .then((res) => {
          this.adresa = res.data.billing_address_1;
          this.qyteti = res.data.billing_city;
          this.email = res.data.billing_email;
          this.emri = res.data.billing_first_name;
          this.mbiemri = res.data.billing_last_name;
          this.tel = res.data.billing_phone;
        });
    }
  },
  data() {
    return {
      value: 1,
      currentUrl: this.$route.name == "CartShop" ? "shop" : "web",
      currentPage: 1,
      payment: false,
      paymentError: "",
      email: "",
      emri: "",
      mbiemri: "",
      adresa: "",
      qyteti: "",
      tel: "",
      paymentDone: false,
      offerEmail: "",
      emriKompanise: "",
      qytetet: [
        "Deçan",
        "Gjakovë",
        "Gllogoc",
        "Gjilan",
        "Dragash",
        "Istog",
        "Kaçanik",
        "Klinë",
        "Fushë Kosovë",
        "Kamenicë",
        "Mitrovicë Jugore",
        "Leposaviq",
        "Lipjan",
        "Novobërdë",
        "Obiliq",
        "Rahovec",
        "Pejë",
        "Podujevë",
        "Prishtinë",
        "Prizren",
        "Skënderaj",
        "Shtime",
        "Shtërpcë",
        "Suharekë",
        "Ferizaj",
        "Viti",
        "Vushtrri",
        "Zubin Potok",
        "Zveçan",
        "Malishevë",
        "Junik",
        "Mamushë",
        "Hani i Elezit",
        "Graçanicë",
        "Ranillug",
        "Partesh",
        "Kllokot",
      ],
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    };
  },
  components: {
    CartProduct,
  },
  methods: {
    changeQuantity(action) {
      if (action == "minus") this.value = this.value - 1;
      else this.value = this.value + 1;
    },
    nextPage(currentPage) {
      this.currentPage = currentPage;
      this.paymentError = "";
    },
    checkSecondPayment() {
      if (
        this.email == "" ||
        this.emri == "" ||
        this.mbiemri == "" ||
        this.adresa == "" ||
        this.qyteti == "" ||
        this.tel == ""
      ) {
        this.paymentError = "Ju lutem plotësoni të gjitha fushat!";
        return false;
      }
      this.paymentError = "";
      return true;
    },
    removeFromCart(product_id) {
      this.$store.commit("changeLoading", true);

      let data = {
        product_id: product_id,
        variation_id: 0,
      };

      this.$axios
        .post(url + "removeFromCart", data, { headers: { ...authHeader() } })
        .then((res) => {
          this.$store.commit("fillCart", res.data);
          this.$store.commit("changeLoading", false);
        })
        .catch((error) => {
          this.$store.commit("changeLoading", false);
        });
    },
    sendForm() {
      this.$store.commit("changeLoading", true);

      let data = {
        billing_address_1: this.adresa,
        billing_city: this.qyteti,
        billing_country: "XK",
        billing_email: this.email,
        billing_first_name: this.emri,
        billing_last_name: this.mbiemri,
        billing_phone: this.tel,
        billing_postcode: "10000",
        billing_state: "XK",
        kuponi: "",
        ship_to_different_address: "0",
        shipping_address_1: this.adresa,
        shipping_city: this.qyteti,
        shipping_first_name: this.emri,
        shipping_last_name: this.mbiemri,
        shipping_method: "DELIVERY",
        shipping_state: "XK",
        terms: "1",
        payment_method: this.payment,
        shipping_postcode: "10000",
      };

      if (this.$store.getters.logged) {
        this.$axios
          .post(url + "pay", data, { headers: { ...authHeader() } })
          .then((res) => {
            if (
              typeof res.data.status !== "undefined" &&
              res.data.status === true
            ) {
              this.$store.commit("fillCart", false);
              this.$store.commit("changeLoading", false);
              // this.paymentDone = true
              window.location = res.data.redirect;
            } else if (
              typeof res.data.result === "undefined" &&
              res.data.result !== "failure"
            ) {
              var res = res.data.split("</TKKPG>");
              var url = JSON.parse(res[1]).redirect;
              window.location.href = url;
              this.$store.commit("changeLoading", false);
            }
          })
          .catch((error) => {});
      } else {
        // var cartData = []
        // this.$store.getters.cart.card.forEach(row => {
        //     cartData.push({'ID': row.ID, 'variation_id': 0, 'quanitity': row.quanitity})
        // });
        data.productsFromCart = this.$store.getters.cart.card;

        this.$axios
          .post(url + "pay", data)
          .then((res) => {
            if (
              typeof res.data.status !== "undefined" &&
              res.data.status === true
            ) {
              this.$store.commit("fillCart", false);
              this.$store.commit("changeLoading", false);
              // this.paymentDone = true
              window.location = res.data.redirect;
            } else if (
              typeof res.data.result === "undefined" &&
              res.data.result !== "failure"
            ) {
              var res = res.data.split("</TKKPG>");
              var url = JSON.parse(res[1]).redirect;
              window.location.href = url;
              this.$store.commit("changeLoading", false);
            }
          })
          .catch((error) => {});
      }
    },
    requestOffer() {
      this.error = "";

      if (this.offerEmail == null || this.offerEmail == "") {
        this.error = "Ju lutem shkruani email-in!";
        return;
      } else if (!this.reg.test(this.offerEmail)) {
        this.error = "Ju lutem shkruani një email valid!";
        return;
      }

      if (this.$store.getters.logged) {
        this.$axios
          .post(
            url + "requestOffer",
            {
              email: this.offerEmail,
              emri: this.emri,
              emriKompanise: this.emriKompanise,
            },
            { headers: { ...authHeader() } }
          )
          .then((res) => {
            alert("success");
          })
          .catch((e) => {
            this.error = "Duhet të keni produkte në shportë!";
          });
      } else {
        this.$axios
          .post(url + "requestOffer", {
            email: this.offerEmail,
            emri: this.emri,
            emriKompanise: this.emriKompanise,
            productsFromCart: this.$store.getters.cart.card,
          })
          .then((res) => {
            alert("success");
          })
          .catch((e) => {
            this.error = "Duhet të keni produkte në shportë!";
          });
      }
    },
  },
};
</script>
