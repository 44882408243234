<style lang="scss" scoped>
    .fullHeight {
        // height:43vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 30px;
        padding-bottom: 30px;

        .blueText{
            color: #007FCF;
        }
        .redText{
            color: red;
        }

        .thankYou{
            font-size: 18px;
        }

        .orderNumber{
            font-size: 22px;
        }

        .blue-button{
            margin-top: 60px;
            margin-bottom: 20px;
            border: 1px solid #007FCF;
            background-color: #007FCF !important;
            width: 299px;
            color: #fff;
            height: 50px;
        }
        

    }
    @media screen and (min-width: 991px){
        .fullHeight{
            min-height: calc(100vh - 497px);
        }
    }
</style>
<template>
    <div class="fullHeight">
        <p class="redText thankYou">Pati një problem me pagesen tuaj.</p>
        <p class="redText orderNumber">Numri porosisë tuaj: #{{this.orderID}}</p>
        <button @click="goToHomepage()" class="btn send-btn btn-danger" type="submit">VAZHDO TE BALLINA</button>
    </div>
</template>

<script>
import { url, authHeader } from '../../../url.js'

    export default {
        data() {
            return {
                orderID: this.$route.params.id,
                rrethPorosise: ''
            }
        },
        watch: {},
        created() {
            let data = {
                order_id: this.orderID
            }
            this.$axios.post(url + 'updateOrderUserMeta', data, {headers: {...authHeader()}}).then(res => {
                this.$store.commit('fillCart', false)
            }).catch(e => {
                console.log(e);
            })
        },
        methods: {
            goToHomepage(){
                this.$router.push('/shop')
            }
        }

    }
</script>