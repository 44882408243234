<style lang="scss" scoped>
    .profile {
        .title {
            color: #007FCF;
        }
        .my-orders {
            font-size: 1.5rem;
        }
        .grey-input {
            background: #F6F6F6;
            border: 1px solid #EEEEEE;
            border-radius: 0;
            padding: 22px 20px;
        }
        .grey-input-select {
            background: #F6F6F6;
            border: 1px solid #EEEEEE;
            border-radius: 0;
            height: 45.5px;
        }
        .login-btn {
            border-radius: 0;
            color: #fff;
            background: #007FCF;
            width: 50%;
            padding: 12px 20px;
            font-size: 14px;
            font-weight: 600;
            margin: 0 auto;
            display: block;
        }
    }
</style>

<template>
    <div>
        <div class="loading" v-if="$store.getters.loading || typeof $store.getters.loading === 'undefined'">
            <div class="spinner-grow text-success"></div>
        </div>

        <div class="container" v-else>
            <div class="profile pt-5 pb-5">
                <div class="d-flex justify-content-center">
                    <h4 class="title mb-3">Profili</h4>
                </div>
                
                <div class="form-group mt-3">
                    <label class="text-muted">Email Address *</label>
                    <input type="email" disabled :placeholder="$store.getters.user.user_email" class="form-control grey-input">
                </div>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label class="text-muted">Emri *</label>
                        <input type="text" :value="typeof $store.getters.user.info !== 'undefined' ? $store.getters.user.info.firstname : ''" @input="emri=$event.target.value" class="form-control grey-input">
                    </div>
                    <div class="form-group col-md-6">
                        <label class="text-muted">Mbiemri *</label>
                        <input type="text" :value="typeof $store.getters.user.info !== 'undefined' ? $store.getters.user.info.lastname : ''" @input="mbiemri=$event.target.value" class="form-control grey-input">
                    </div>
                </div>
                <div class="form-group">
                    <label class="text-muted">Adresa *</label>
                    <input type="text" :value="typeof $store.getters.user.info !== 'undefined' ?  $store.getters.user.info.address : ''" @input="adresa=$event.target.value" class="form-control grey-input">
                </div>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label class="text-muted">Qyteti *</label>
                        <select @change="qyteti=$event.target.value" class="form-control grey-input-select">
                            <option value="" :selected="typeof $store.getters.user.info === 'undefined'">Zgjidh Qytetin...</option>
                            <option :selected="typeof $store.getters.user.info !== 'undefined' ? $store.getters.user.info.city==qyteti : ''" v-for="qyteti in qytetet" :value="qyteti" :key="qyteti">{{qyteti}}</option>
                        </select>
                    </div>
                    <div class="form-group col-md-6">
                        <label class="text-muted">Nr. i Telefonit *</label>
                        <input type="text" :value="typeof $store.getters.user.info !== 'undefined' ? $store.getters.user.info.phone : ''" @input="tel=$event.target.value" class="form-control grey-input">
                    </div>
                </div>
                <div class="alert alert-danger mt-2 mb-0" v-if="paymentError">
                    {{ paymentError }}
                </div>

                <button class="btn login-btn mt-4" @click="validate() ? edit() : ''">EDITO</button>
            </div>
        </div>
    </div>
</template>

<script>
import {url, authHeader} from '../../url'

export default {
    mounted(){
        this.$store.commit('changeLoading', false)
    },
    data() {
        return {
            value: 1,
            currentUrl: this.$route.name=='CartShop' ? 'shop' : 'web',
            currentPage: 1,
            payment: false,
            paymentError: '',
            email: '',
            emri: '',
            mbiemri: '',
            adresa: '',
            qyteti: '',
            tel: '',
            qytetet: [
                'Deçan',
                'Gjakovë',
                'Gllogoc',
                'Gjilan',
                'Dragash',
                'Istog',
                'Kaçanik',
                'Klinë',
                'Fushë Kosovë',
                'Kamenicë',
                'Mitrovicë Jugore',
                'Leposaviq',
                'Lipjan',
                'Novobërdë',
                'Obiliq',
                'Rahovec',
                'Pejë',
                'Podujevë',
                'Prishtinë',
                'Prizren',
                'Skënderaj',
                'Shtime',
                'Shtërpcë',
                'Suharekë',
                'Ferizaj',
                'Viti',
                'Vushtrri',
                'Zubin Potok',
                'Zveçan',
                'Malishevë',
                'Junik',
                'Mamushë',
                'Hani i Elezit',
                'Graçanicë',
                'Ranillug',
                'Partesh',
                'Kllokot',
            ],
            
        }
    },
    methods: {
        validate() {
            // if(this.emri == '')
            //     this.emri = this.$store.getters.user.info.firstname
            // if(this.mbiemri == '')
            //     this.mbiemri = this.$store.getters.user.info.firstname
            // if(this.adresa == '')
            //     this.adresa = this.$store.getters.user.info.address
            // if(this.qyteti == '')
            //     this.qyteti = this.$store.getters.user.info.city
            // if(this.tel == '')
            //     this.tel = this.$store.getters.user.info.phone

            // if(this.email=='' || this.emri=='' || this.mbiemri=='' || this.adresa=='' || this.qyteti=='' || this.tel=='')
            // {
            //     this.paymentError = 'Ju lutem plotësoni të gjitha fushat!'
            //     return false;
            // }
            // this.paymentError = ''
            return true;
        },
        edit() {
            this.$store.commit('changeLoading', true)
            console.log(this.emri);
            let data = {
                'firstName': this.emri,
                'lastName': this.mbiemri,
                'address': this.adresa,
                'city': this.qyteti,
                'phone': this.tel 
            }
            
            this.$axios.post(url+'updateProfile', data, { headers: {...authHeader() }} ).then(res => {
                this.$store.commit('changeLoading', false)
                location.reload()
            }).catch(e => {
                console.log(e);
            })
            
        }
    }
};
</script>
