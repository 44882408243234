<style lang="scss">
  .loading {
    height: 50vh;
    position: relative;
    .spinner-grow {
      position: absolute;
      top: 44%;
      left: 50%;
    }
  }
</style>
<style lang="scss" scoped>
  .main-content {
    min-height: calc(100vh - 491px);
    padding-bottom: 2.5rem;
  }
</style>
<template>
  <div class="main-content">
    <!-- Outlet for children routes -->
    <!-- <keep-alive exclude="['post', 'category', 'search', 'tag']" :max="3"> -->
    <!-- <keep-alive :max="2" exclude="['CartWeb', 'CartShop', 'ProductDetails']"> -->
      <router-view></router-view>
    <!-- </keep-alive> -->
  </div>
</template>

<script>
import { url, authHeader } from '../../../url'

export default {
  data() {
    return {

    };
  },
  methods: {},
};
</script>
