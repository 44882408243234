import Vue from 'vue'
import Vuex, { Store } from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    loading: true,
    logged: localStorage.getItem('token') ? true : false,
    cart: false,
    user: false,
    categories: [],
    bizneset: [],
    subCategories: []
  },
  mutations: {
    changeLoading (state, loading) {
      state.loading = loading
    },
    changeLogged (state, { logged, token }) {
      if(logged == true)
        localStorage.setItem('token', token)
      else
        localStorage.removeItem('token')
      
      state.logged = logged
    },
    setSubcategories(state, subCategories) {
      state.subCategories = subCategories
    },
    fillCart (state, cart) {
      state.cart = cart
    },
    fillUser (state, user) {
      state.user = user
      state.loading = false
    },
    fillCategories(state, categories) {
      state.categories = categories
    },
    fillBizneset(state, bizneset) {
      state.bizneset = bizneset
    }
  },
  actions: {
  },
  modules: {
  },
  getters: {
    loading: state => {
      return state.loading
    },
    logged: state => {
      return state.logged
    },
    cart: state => {
      return state.cart
    },
    user: state => {
      return state.user
    },
    categories: state => {
      return state.categories
    },
    bizneset: state => {
      return state.bizneset
    },
    subCategories: state => {
      console.log(state)
      return state.subCategories
    }
  }
})

export default store