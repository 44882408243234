import Vue from "vue"
import router from './router'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import axios from "axios"
import store from './store'
import '../src/assets/scss/main.scss';
import VueBootstrapToasts from "vue-bootstrap-toasts";



Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.use(VueBootstrapToasts);
// Vue.use(VueSlickCarousel);
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

new Vue({
  render: (h) => h(router.options.routes[0].component),
  router,
  store,
  data() {
    return {
      loading: true,
    }
  },
}).$mount("#app")