<style scoped>
#main {
  position: relative;
}
</style>

<template>
  <div id="main">
    <Navbar />
    <MainContent />
    <Footer />
  </div>
</template>

<script>
import { urlShop } from "../../../url";
import MainContent from "./MainContent.vue";
import Navbar from "./Navbar.vue";
import Footer from "../Footer.vue";

export default {
  beforeCreate() {
    this.$axios
      .get(urlShop + "allCategories")
      .then((res) => {
        this.$store.commit("fillCategories", res.data);
      })
      .catch((e) => {});
  },
  data() {
    return {};
  },
  components: {
    Navbar,
    Footer,
    MainContent,
  },
  methods: {},
};
</script>
